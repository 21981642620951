<template>
  <div>
    <v-wait for="loadingTreeData">
      <template slot="waiting">
        <content-placeholders :rounded="true" class="loader">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div v-if="forFinQuery && isMortgageLender">
        <div class="row p-2">
          <div class="col-12 ml-1">
            <div
              v-for="(option, key, index) in firmographicMBCDefaultOptions"
              :key="index"
            >
              <input
                type="checkbox"
                checked="true"
                aria-disabled="true"
                class=" mr-1 rounded  firmo-disabled-checked "
              />
              <span class="firmo-disabled-text" disabled="true">
                {{ option.name }}
              </span>
            </div>
          </div>
        </div>
        <div class="row no-gutters technographics-segment pt-1">
          <div class="col-sm-5">
            <h6 class="">Firmographics Categories</h6>
            <div
              class="card std-border mb-0 technographics-menu tree-menu segment-column"
            >
              <div class="card-body p-0">
                <div
                  v-for="(serviceGroup, index) in treeData"
                  :key="index"
                  class="pb-3"
                >
                  <div class="font-weight-bold text-uppercase px-2 pt-2">
                    {{ serviceGroup.text }}
                  </div>
                  <ul class="list-group">
                    <li
                      :class="[
                        {
                          active: selectedService.data === childService.data
                        },
                        'list-group-item pl-4'
                      ]"
                      v-for="(childService, index) in serviceGroup.children"
                      :key="index"
                      @click="selectService(childService, serviceGroup)"
                    >
                      {{ childService.text }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div :class="['col-sm-7', 'px-2 details-holder']">
            <h6 class="" v-if="selectedParent.text">
              {{ selectedParent.text }} : {{ selectedService.text }}
            </h6>
            <div class="card std-border mb-0 techno-details segment-column">
              <div class="card-body p-1">
                <LiquorTree
                  :data="treeData"
                  :multiple="false"
                  :showChildren="true"
                  :parentSelect="false"
                  :options="treeOptions"
                  ref="solutionTree"
                  class="pt-4"
                  @node:selected="nodeSelectedMbc"
                  @node:checked="nodeCheckedMbc"
                  @node:unchecked="nodeUnCheckedMbc"
                >
                  <span class="tree-text" slot-scope="{ node }">
                    <template>
                      <span :id="'tg-' + node.id">
                        {{ node.text }}
                      </span>
                    </template>
                  </span>
                </LiquorTree>
              </div>
            </div>
          </div>
        </div>
        <!--

                        <div class="row p-2">
          <div class="col-12 filter-group-modifier">
            <div
              class="d-flex mb-2"
              v-for="(child, index) in firmographicsElementList"
              :key="index"
            >
              <FirmographicsDataElement
                :queryRule="firmoRule"
                :query="child"
                :rootQuery="firmographicsElementList"
                :index="index"
                @cloneRule="cloneRule"
                @removeRule="removeRule"
                @updateRule="updateRule"
              ></FirmographicsDataElement>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 ml-1">
            <button
              type="button"
              class="btn btn-primary rounded mr-1"
              @click="addDataElement()"
            >
              Add Data Element
            </button>
          </div>
        </div> -->
      </div>
      <LiquorTree
        :data="elements"
        :multiple="false"
        :showChildren="true"
        :parentSelect="false"
        :options="treeOptions"
        ref="firmographicsTree"
        @node:checked="nodeChecked"
        @node:unchecked="nodeUnChecked"
        v-else
      >
        <span class="tree-text" slot-scope="{ node }">
          <template>
            <span :id="'firmo-' + node.id.toString()">
              {{ node.text }}
            </span>
            <b-popover
              :target="'firmo-' + node.id.toString()"
              triggers="hover"
              placement="right"
              boundary="window"
              v-if="node.data.description"
            >
              <div class="font-weight-bold" v-if="node.data.sec_title">
                {{ node.data.sec_title }}
              </div>
              {{ node.data.description }}
            </b-popover>
          </template>
        </span>
      </LiquorTree>
    </v-wait>
  </div>
</template>

<script>
//global
import { mapState, mapMutations, mapGetters } from 'vuex'
//ui
import LiquorTree from 'liquor-tree'
//api
//import deepClone from '@/utilities.js'
import metricsAPI from '@/api/finapps/metrics'
//import FirmographicsDataElement from './FirmographicsDataElement'

export default {
  name: 'RWFirmographics',
  components: {
    LiquorTree
    //FirmographicsDataElement
  },
  props: {
    selectedReport: {
      type: Object,
      required: true
    },
    showOutput: {
      type: Boolean,
      default: true
    },
    renderedIn: {
      type: String,
      default: 'Report Writer'
    },
    isMortgageLender: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      treeOptions: {
        checkbox: true,
        checkOnSelect: false,
        parentSelect: false,
        autoCheckChildren: false,
        autoDisableChildren: false
      },
      treeData: null,
      selectedParent: {},
      selectedService: {},
      selectedItem: {},
      elements: []
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      elementsInfo: state => state.elementsInfo,
      activeSegment: state => state.activeSegment,
      reportElements: state => state.reportElements,
      elementSequenceID: state => state.elementSequenceID,
      defaultOptions: state => state.defaultOptions,
      firmographicOptions: state => state.firmographicOptions,
      firmographicMetrics: state => state.firmographicMetrics,
      firmographicCommonMetrics: state => state.firmographicCommonMetrics,
      firmographicMBCDefaultOptions: state =>
        state.firmographicMBCDefaultOptions,
      firmographicsElementList: state => state.firmographicsElementList
    }),
    ...mapState('AdvancedQuery', {
      queryElements: state => state.queryElements,
      //isMortgageLender: state => state.isMortgageLender,
      queryRules: state => state.queryRules
    }),
    ...mapGetters('AdvancedQuery', ['getfirmographicMBCOptions']),
    forIndustryReporter() {
      return this.renderedIn === 'Industry Reporter'
    },
    forFINReporter() {
      return this.renderedIn === 'FIN Reporter'
    },
    forReportWriter() {
      return this.renderedIn === 'Report Writer'
    },
    forFinQuery() {
      return this.renderedIn === 'FIN Query'
    },
    noDefaultElements() {
      return this.forReportWriter || this.forFINReporter
    },
    segmentElementsInfo() {
      return this.elementsInfo.filter(ei => ei.segment === 'firmographics')
    },
    fiType() {
      return this.selectedReport.fi_type
    },
    defaultElements() {
      return this.defaultOptions.map(de => {
        return {
          name: de.name,
          type: 'default',
          fi_type: 'all',
          property: de.property,
          description: this.elementsInfo.find(ei => ei.property === de.property)
            .description
        }
      })
    },
    fiTypeOptions() {
      return this.firmographicOptions.filter(
        option => option.fi_type === 'all' || option.fi_type === this.fiType
      )
    },
    allOptions() {
      return [...this.defaultElements, ...this.fiTypeOptions]
    },
    activeCustomElementIDs() {
      return this.reportElements
        .filter(re => ['metrics', 'common_metric'].includes(re.type))
        .map(te => parseInt(te.id))
    },
    activeQueryElementIDs() {
      return this.queryElements
        .filter(qe => ['metrics', 'common_metric'].includes(qe.type))
        .map(te => parseInt(te.elementID))
      // this.isMortgageLender
      //   ? this.queryElements
      //       .filter(re => re.type === 'firmographics')
      //       .map(te => te.name)
    },
    activeElementIDs() {
      return [...this.activeCustomElementIDs, ...this.activeQueryElementIDs]
    },
    activeParentElementIDs() {
      return [
        ...this.reportElements
          .filter(re => re.type === 'firmographics')
          .map(te => te.property),
        ...this.queryElements
          .filter(re => re.type === 'firmographics')
          .map(te => te.property)
      ]
    },
    activeChildElementIDs() {
      return [
        ...this.reportElements
          .filter(re => re.type === 'firmographics')
          .map(te => te.elementOption),
        ...this.queryElements
          .filter(re => re.type === 'firmographics' && re.elementOption)
          .map(te => te.elementOption.map(eo => eo.value))
      ]
    },
    activeNodeIds() {
      return this.reportElements
        .filter(re => re.type === 'firmographics')
        .map(te => te.name)
    },
    isActiveSegment() {
      return this.activeSegment === 'Firmographics'
    },
    firmoRule() {
      return this.queryRules.find(rule => rule.label === 'Firmographics')
    }
  },
  methods: {
    ...mapMutations('ReportWriter', [
      'addReportElement',
      'addCustomizedElement',
      'removeReportElement',
      'removeCustomizedElement',
      'setFirmographicMetrics',
      'setFirmographicCommonMetrics',
      'removeElementByProperty',
      'updateSequenceID',
      'saveFirmographicsElementList',
      'removeReportElementByName'
    ]),

    loadInitData() {
      if (!(this.isMortgageLender && this.forFinQuery)) {
        this.getMetricsData().then(() => {
          this.prepareTreeData()
        })
      } else {
        this.treeData = this.convertToHierarichalData(this.firmoRule.children)
        this.setDefaultService()
      }
    },
    setDefaultService() {
      let defaultService = this.treeData[0]
      this.selectedParent = defaultService
      this.selectedService = defaultService.children[0]
      this.$refs.solutionTree.setModel(this.selectedService.children)
    },
    convertToHierarichalData(children = [], id = null) {
      //link = 'parent_id'
      if (id) {
        return children.element_options.map(option => {
          return {
            id: option.value,
            text: option.label,
            data: option,
            state: {
              expanded: true,
              checked: this.activeChildElementIDs.includes(option.value),
              disabled: false //this.activeQueryElementIDs.includes(service.id)
            },
            children: this.convertToChildNode(
              children,
              option.label
              //this.selectedParent.text
            )
          }
        })
      } else {
        return children
          .filter(child => child.operators.action_taken != undefined)
          .map(child => {
            return {
              id: child.id,
              text: child.label,
              data: child,
              state: {
                expanded: true,
                checked: this.activeParentElementIDs.includes(
                  child.element_property
                ),
                disabled: false //this.activeQueryElementIDs.includes(service.id)
              },
              children: this.convertToHierarichalData(child.operators, child.id)
            }
          })
      }
    },
    convertToChildNode(operators, serviceText) {
      let nodes = []
      operators.output_type.forEach(ot => {
        operators.action_taken.forEach(at => {
          let temp =
            serviceText.replace(' (1-4 Units)', '').replace(' (5+ Units)', '') +
            ': ' +
            at.label +
            ': ' +
            ot.label.replace(/Loan |Record /gi, '')
          nodes.push({
            id: at.value + '__' + ot.value,
            text: at.label + ': ' + ot.label,
            data: at,
            state: {
              expanded: true,
              checked: this.activeNodeIds.includes(temp),
              disabled: false
            }
          })
        })
      })
      return nodes
    },

    selectService(service, parent) {
      let childService = this.convertToChildNode(
        this.treeData.find(node => node.id === parent.id).data.operators,
        service.text
        // parent.text
      )
      // this.treeData
      //   .find(node => node.id === parent.id)
      //   .children.find(child => child.id === service.id).children
      this.selectedParent = parent
      this.selectedService = service
      this.$refs.solutionTree.setModel(childService)
    },
    nodeSelectedMbc(node) {
      this.selectedItem = node
    },
    nodeCheckedMbc(node) {
      this.updateSequenceID()
      let ids = node.id.split('__')
      //this.selectedItem = node
      let element = {
        name: this.getDisplayText(node),
        type: 'firmographics',
        property: this.selectedParent.data.element_property,
        seqID: this.elementSequenceID,
        elementOption: this.selectedService.id,
        actionTaken: ids[0],
        outputType: ids[1]
      }
      this.addReportElement(element)
      this.addCustomizedElement(element)
      // this.addToReportElements(node)
    },
    nodeUnCheckedMbc(node) {
      let name = this.getDisplayText(node)
      this.removeCustomizedElement({
        name: name,
        type: 'firmographics'
      })
      this.removeReportElementByName({
        name: name,
        type: 'firmographics'
      })
    },
    getMetricsData() {
      if (
        this.firmographicMetrics.length &&
        this.firmographicCommonMetrics.length
      ) {
        return Promise.resolve()
      }

      this.$wait.start('loadingTreeData')
      return metricsAPI
        .firmographicMetrics()
        .then(res => {
          this.setFirmographicMetrics(res.metrics)
          this.setFirmographicCommonMetrics(res.common_metric)
        })
        .finally(() => {
          this.$wait.end('loadingTreeData')
        })
    },
    isElementAddedInQuery(option) {
      return (
        this.forFinQuery &&
        this.queryElements.some(
          qe => qe.type === 'firmographics' && qe.property === option.property
        )
      )
    },
    isElementAddedInReport(option) {
      return this.reportElements.some(
        re => re.type === 'firmographics' && re.property === option.property
      )
    },
    isElementAdded(option) {
      return (
        this.isElementAddedInReport(option) ||
        this.isElementAddedInQuery(option)
      )
    },
    isMetricNode(node) {
      return ['metrics', 'common_metric'].includes(node.data.type)
    },
    getNodeData(option) {
      if (option.property) {
        return {
          type: 'custom',
          fi_type: option.fi_type,
          property: option.property,
          description:
            option.type === 'default' || option.type === 'mbc'
              ? option.description
              : this.segmentElementsInfo.find(
                  ei => ei.property === option.property
                ).description
        }
      } else {
        let metric =
          option.type === 'metrics'
            ? this.firmographicMetrics.find(
                fm => fm.name === option.metric_name
              )
            : this.firmographicCommonMetrics.find(fcm => fcm.id === option.id)
        return {
          type: option.type,
          fi_type: option.fi_type,
          metric_id: metric.id,
          sec_title: metric.sec_title,
          description: metric.description
        }
      }
    },
    getNodeState(option) {
      if (option.type === 'default') {
        return {
          checked: this.noDefaultElements
            ? this.isElementAdded(option)
            : !this.forIndustryReporter,
          disabled: this.noDefaultElements
            ? this.isElementAddedInQuery(option.data)
            : true
        }
      } else if (option.property) {
        return {
          checked: this.forIndustryReporter
            ? false
            : this.isElementAdded(option),
          disabled: this.forIndustryReporter
            ? true
            : this.isElementAddedInQuery(option)
        }
      } else {
        let metric =
          option.type === 'metrics'
            ? this.firmographicMetrics.find(
                fm => fm.name === option.metric_name
              )
            : this.firmographicCommonMetrics.find(fcm => fcm.id === option.id)

        return {
          checked: this.activeElementIDs.includes(metric.id),
          disabled: this.activeQueryElementIDs.includes(metric.id)
        }
      }
    },
    prepareTreeData() {
      this.elements = []

      this.allOptions.forEach(option => {
        this.elements.push({
          id: this.elements.length + 1,
          text: option.name,
          data: this.getNodeData(option),
          state: this.getNodeState(option)
        })
      })

      this.$refs.firmographicsTree.setModel(this.elements)
    },
    nodeChecked(node) {
      this.updateSequenceID()
      let element = this.isMetricNode(node)
        ? this.prepareMetricElement(node)
        : this.prepareFirmographicElement(node)
      this.addReportElement(element)
      this.addCustomizedElement(element)
    },
    prepareMetricElement(node) {
      return {
        id: node.data.metric_id,
        seqID: this.elementSequenceID,
        name: node.text,
        type: node.data.type,
        scope: 'single',
        property: 'value',
        span: 2,
        set: 1
      }
    },
    prepareFirmographicElement(node) {
      return {
        name: node.text,
        type: 'firmographics',
        property: node.data.property,
        seqID: this.elementSequenceID
      }
    },
    nodeUnChecked(node) {
      if (this.isMetricNode(node)) {
        this.removeCustomizedElement({
          id: node.data.metric_id,
          type: node.data.type
        })
        this.removeReportElement({
          id: node.data.metric_id,
          type: node.data.type
        })
      } else {
        this.removeCustomizedElement({
          property: node.data.property,
          type: 'firmographics'
        })
        this.removeElementByProperty({
          property: node.data.property,
          type: 'firmographics'
        })
      }
    },
    getDisplayText(item) {
      let text = ''
      // let atList = item.actionTaken.map(at => at.label)
      // text += '[' + atList.join(', ') + ']: '
      text +=
        this.selectedService.text
          .replace(' (1-4 Units)', '')
          .replace(' (5+ Units)', '') + ': '
      text += item.data.text.replace(/ Loan| Record/gi, '')
      //text += this.selectedParent.text + ': '

      return text
    }
  },
  watch: {
    fiType() {
      if (this.isActiveSegment) {
        this.getMetricsData().then(() => {
          this.prepareTreeData()
        })
      }
    }
  }
}
</script>
<style scoped>
input[type='checkbox'][aria-disabled='true'] {
  background-color: #3a99fc !important;
  border-color: #218eff !important;
  pointer-events: none;
}
.firmo-disabled-text {
  color: #817c7c;
}
</style>
