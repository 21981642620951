<template>
  <div>
    <div class="row no-gutters metrics-segment">
      <div class="col-sm-3">
        <h6 class="">Metric Categories</h6>
        <div class="card std-border mb-0 metric-menu tree-menu segment-column">
          <div class="card-body p-0">
            <div class="pt-2">
              <ul class="common-metric-list-group pl-0 font-weight-bold">
                <li
                  :class="[
                    {
                      active: selectedMetric.product_id === product.product_id
                    },
                    'py-1 list-group-item pl-3'
                  ]"
                  v-for="(product, index) in metricsMenu"
                  :key="index"
                  @click="selectProduct(product)"
                >
                  {{ product.name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div :class="[showInfo ? 'col-sm-6' : 'col-sm-9', 'px-2 details-holder']">
        <h6 class="" v-if="selectedMetric.name">
          {{ selectedMetric.name }}
        </h6>
        <h6 v-else class="mb-4"></h6>

        <div class="card std-border mb-0 metric-details segment-column">
          <div class="card-body p-1">
            <v-wait for="loadingCommonTreeData">
              <template slot="waiting">
                <content-placeholders :rounded="true" class="loader">
                  <content-placeholders-text
                    :lines="20"
                  ></content-placeholders-text>
                </content-placeholders>
              </template>

              <div class="row p-3">
                <div class="offset-sm-4 col-sm-2 text-right align-self-center">
                  <h6 class="mb-0">Search</h6>
                </div>

                <div class="col-sm-6 pl-0">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Type text to search..."
                    v-model="searchText"
                  />
                </div>
              </div>

              <LiquorTree
                :data="treeData"
                :multiple="false"
                :showChildren="true"
                :options="treeOptions"
                :filter="searchText"
                @node:clicked="nodeClicked"
                @node:checked="nodeChecked"
                @node:unchecked="nodeUnChecked"
                ref="commonMetricsTree"
              >
                <span class="tree-text" slot-scope="{ node }">
                  <template>
                    <span :id="'cm-' + node.id.toString()">
                      {{ node.text }}
                    </span>

                    <b-popover
                      :target="'cm-' + node.id.toString()"
                      triggers="hover"
                      placement="right"
                      boundary="window"
                    >
                      <div class="font-weight-bold">
                        {{ node.data.bank_sec_title }}
                      </div>
                      {{
                        getMatchingDescription(
                          node.data.bank_metric_id,
                          node.data.cu_metric_id
                        ).bank_description
                      }}

                      <div class="font-weight-bold mt-3">
                        {{ node.data.cu_sec_title }}
                      </div>
                      {{
                        getMatchingDescription(
                          node.data.bank_metric_id,
                          node.data.cu_metric_id
                        ).cu_description
                      }}
                    </b-popover>
                  </template>
                </span>
              </LiquorTree>
            </v-wait>
          </div>
        </div>
      </div>

      <div class="col-sm-3" v-if="showInfo">
        <h6 class="mb-4"></h6>
        <div class="card std-border mb-0 metric-info segment-column">
          <div class="card-body p-2 mt-1" v-if="selectedNode">
            <h6 class="mb-0">{{ selectedNode.data.tree_display }}</h6>
            <span class="sub-title">{{ selectedNode.data.sec_title }}</span>
            <p class="mt-3">{{ selectedNode.data.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//global
import { mapState, mapMutations } from 'vuex'
//api
import commonMetricsMappingsAPI from '@/api/finapps/common_metrics_mappings'
//need to refactor
import LiquorTree from 'liquor-tree'

export default {
  name: 'AQCommonMetrics',
  components: {
    LiquorTree
  },
  props: {
    selectedReport: {
      type: Object,
      required: true
    },
    showOutput: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      treeData: null,
      searchText: null,
      selectedNode: null,
      selectedMetric: {},
      treeOptions: {
        checkbox: true,
        checkOnSelect: false,
        parentSelect: false,
        autoCheckChildren: false,
        autoDisableChildren: false
      },
      metricsMenu: [
        {
          product_id: 1,
          name: 'Profit'
        },
        {
          product_id: 2,
          name: 'Growth'
        },
        {
          product_id: 3,
          name: 'Risk'
        }
      ],
      metricDescriptions: null
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      activeSegment: state => state.activeSegment,
      reportElements: state => state.reportElements,
      elementSequenceID: state => state.elementSequenceID,
      commonMetricTreeData: state => state.commonMetricTreeData
    }),
    ...mapState('AdvancedQuery', {
      queryElements: state => state.queryElements
    }),
    productMetrics() {
      if (!this.selectedMetric) {
        return []
      }
      return this.commonMetricTreeData.filter(
        td => td.product_id === this.selectedMetric.product_id
      )
    },
    productParentMetrics() {
      let parentElements = []
      this.productMetrics.forEach(pm => {
        let metricParent = this.productMetrics.find(
          metric => metric.key_id === pm.key_parent_id
        )
        if (!metricParent) {
          let isDuplicate = parentElements.find(el => el.id === pm.id)
          if (!isDuplicate) {
            parentElements.push(pm)
          }
        }
      })
      return parentElements
    },
    editMode() {
      return this.selectedReport.id !== null
    },
    showInfo() {
      return !this.showOutput
    },
    fiType() {
      return this.selectedReport.fi_type
    },
    activeCustomElementIDs() {
      return this.reportElements
        .filter(re => re.type === 'common_metric')
        .map(te => parseInt(te.id))
    },
    activeQueryElementIDs() {
      return this.queryElements
        .filter(qe => qe.type === 'common_metric')
        .map(te => parseInt(te.elementID))
    },
    activeElementIDs() {
      return [...this.activeCustomElementIDs, ...this.activeQueryElementIDs]
    },
    isActiveSegment() {
      return this.activeSegment === 'Financial Metrics'
    }
  },
  methods: {
    ...mapMutations('ReportWriter', [
      'addReportElement',
      'addCustomizedElement',
      'removeReportElement',
      'removeCustomizedElement',
      'removeMetricFromGraphEl',
      'setCommonMetricTreeData',
      'updateSequenceID'
    ]),
    loadInitData() {
      this.selectedMetric = this.metricsMenu[0]
      Promise.all([
        this.getMetricsData(),
        this.getMetricTreeDescriptions()
      ]).then(() => {
        this.prepareTree()
      })
    },
    getMetricsData() {
      if (this.commonMetricTreeData.length) {
        return Promise.resolve()
      }

      this.$wait.start('loadingCommonTreeData')

      return commonMetricsMappingsAPI
        .metrics(this.selectedReport.id)
        .then(res => {
          this.setCommonMetricTreeData(res.common_metrics)
        })
        .finally(() => {
          this.$wait.end('loadingCommonTreeData')
        })
    },
    getMetricTreeDescriptions() {
      if (this.metricDescriptions) {
        return Promise.resolve()
      }

      return commonMetricsMappingsAPI.metricDescriptions().then(res => {
        this.metricDescriptions = res.common_metrics
      })
    },
    getMatchingDescription(bankMetricID, cuMetricID) {
      if (!this.metricDescriptions) {
        return {}
      }

      return (
        this.metricDescriptions.find(
          m =>
            m.bank_metric_id === bankMetricID && m.cu_metric_id === cuMetricID
        ) || {}
      )
    },
    prepareTree() {
      this.treeData = this.productParentMetrics.map(ppm => {
        return this.createTreeNode(ppm, this.productMetrics)
      })
      this.$refs.commonMetricsTree.setModel(this.treeData)
    },
    createTreeNode(metric, metrics) {
      return {
        id: metric.id,
        text: metric.name,
        data: metric,
        state: {
          expanded: true,
          checked: this.activeElementIDs.includes(metric.id),
          disabled: this.activeQueryElementIDs.includes(metric.id)
        },
        children: this.convertToTree(metrics, metric.key_id)
      }
    },
    convertToTree(metrics = [], id = null, link = 'key_parent_id') {
      return metrics
        .filter(metric => metric[link] === id)
        .map(metric => this.createTreeNode(metric, metrics))
    },
    selectProduct(menu) {
      this.selectedMetric = menu
      this.searchText = null
      this.prepareTree()
    },
    addToReportElements(node) {
      let element = {
        id: node.id,
        seqID: this.elementSequenceID,
        name: node.text,
        type: 'common_metric',
        scope: 'single',
        property: 'value',
        span: 2,
        set: 1
      }
      this.addReportElement(element)
      this.addCustomizedElement(element)
    },
    nodeClicked(node) {
      this.selectedNode = node
    },
    nodeChecked(node) {
      this.updateSequenceID()
      this.selectedNode = node
      this.addToReportElements(node)
    },
    nodeUnChecked(node) {
      this.removeCustomizedElement({ id: node.data.id, type: 'common_metric' })
      this.removeMetricFromGraphEl({
        id: node.data.id,
        type: 'common_metric'
      })
      this.removeReportElement({ id: node.data.id, type: 'common_metric' })
    }
  },
  watch: {
    fiType: {
      immediate: true,
      handler: function() {
        if (this.isActiveSegment && this.fiType === 'both') {
          this.loadInitData()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.common-metric-list-group {
  list-style: none;

  .list-group-item {
    cursor: pointer;
    border: none;
    padding: 0.3rem;
    background-color: transparent;
    font-weight: 500;

    &.active {
      background-color: #20a8d8;
    }

    &:hover {
      background-color: #20a8d8;
    }
  }
}
</style>
