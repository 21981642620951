<template>
  <div>
    <ul class="">
      <li class="pt-1">
        <span class="font-weight-bold pr-1">-</span>
        Metric Value
      </li>
      <li class="pt-1" v-if="fiType != ''">
        <span class="font-weight-bold pr-1">-</span>
        {{ fiType }}
      </li>
      <li class="pt-1" @click.stop="toggleEditListItem()">
        <div class="row" v-if="!editable">
          <div :class="[editable ? 'col-sm-10' : 'col-sm-12']">
            <span class="font-weight-bold pr-1">-</span>
            {{ elementSet.text }}
          </div>
          <div class="col-sm-2" v-if="editable">
            <i class="fa fa-caret-down"></i>
          </div>
        </div>
        <div v-else>
          <span class="font-weight-bold pr-1">-</span>
          <span class="multiselect-holder d-inline-block">
            <Multiselect
              track-by="id"
              label="text"
              :allowEmpty="false"
              :showLabels="false"
              :options="setOptions"
              :value="elementSet"
              class="rw-multi-select"
              @select="updateSet"
            ></Multiselect>
          </span>
        </div>
      </li>
      <li class="pt-1">
        <div class="row" v-if="!editable">
          <div :class="[editable ? 'col-sm-10' : 'col-sm-12']">
            <span class="font-weight-bold pr-1">-</span>
            {{ elementSpan.text }}
          </div>
          <div class="col-sm-2" v-if="editable">
            <i class="fa fa-caret-down"></i>
          </div>
        </div>
        <div v-else>
          <span class="font-weight-bold pr-1">-</span>
          <span class="multiselect-holder d-inline-block">
            <Multiselect
              track-by="id"
              label="text"
              :allowEmpty="false"
              :showLabels="false"
              :options="spanOptions"
              :value="elementSpan"
              class="rw-multi-select"
              @select="updateSpan"
            ></Multiselect>
          </span>
        </div>
      </li>
      <li class="pt-1">
        <div class="row" v-if="!editable">
          <div :class="[editable ? 'col-sm-10' : 'col-sm-12']">
            <span class="font-weight-bold pr-1">-</span>
            {{ elementScope.text }}
          </div>
          <div class="col-sm-2" v-if="editable">
            <i class="fa fa-caret-down"></i>
          </div>
        </div>
        <div v-else>
          <span class="font-weight-bold pr-1">-</span>
          <span class="multiselect-holder d-inline-block">
            <Multiselect
              track-by="id"
              label="text"
              :allowEmpty="false"
              :showLabels="false"
              :options="scopeOptions"
              :value="elementScope"
              class="rw-multi-select"
              @select="updateScope"
            ></Multiselect>
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'
export default {
  name: 'metricsOptions',
  props: {
    element: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    fiType: {
      type: String,
      default: ''
    }
  },
  components: {
    Multiselect
  },
  data() {
    return {
      setOptions: [
        { id: 1, text: 'As Reported' },
        { id: 2, text: 'Merger Restated' }
      ],
      spanOptions: [
        { id: 1, text: 'Quarterly' },
        { id: 2, text: 'Trailing-12 Months' },
        { id: 3, text: 'Yearly' }
      ],
      scopeOptions: [
        { id: 'trend', text: '5-Period Trend' },
        { id: 'single', text: 'Current Period' }
      ]
    }
  },
  computed: {
    ...mapGetters('ReportWriter', [
      'globalSettings',
      'globalFinMetrics',
      'globalFinStatements'
    ]),
    elementSet() {
      if (
        ['financial_statements', 'common_financial_statements'].includes(
          this.element.type
        ) &&
        this.globalSettings['Financial Statements'] === 'true'
      ) {
        return this.globalFinStatements['set']
      } else if (
        ['common_metric', 'metrics'].includes(this.element.type) &&
        this.globalSettings['Financial Metrics'] === 'true'
      ) {
        return this.globalFinMetrics['set']
      } else return this.setOptions.find(set => set.id === this.element.set)
    },
    elementSpan() {
      if (
        ['financial_statements', 'common_financial_statements'].includes(
          this.element.type
        ) &&
        this.globalSettings['Financial Statements'] === 'true'
      ) {
        return this.globalFinStatements['span']
      } else if (
        ['common_metric', 'metrics'].includes(this.element.type) &&
        this.globalSettings['Financial Metrics'] === 'true'
      ) {
        return this.globalFinMetrics['span']
      } else return this.spanOptions.find(span => span.id === this.element.span)
    },
    elementScope() {
      if (
        ['financial_statements', 'common_financial_statements'].includes(
          this.element.type
        ) &&
        this.globalSettings['Financial Statements'] === 'true'
      ) {
        return this.globalFinStatements['scope']
      } else if (
        ['common_metric', 'metrics'].includes(this.element.type) &&
        this.globalSettings['Financial Metrics'] === 'true'
      ) {
        return this.globalFinMetrics['scope']
      } else
        return this.scopeOptions.find(scope => scope.id === this.element.scope)
    }
  },
  methods: {
    updateSpan(span) {
      this.$emit('updateElement', {
        elementId: this.element.id,
        id: span.id,
        type: this.element.type,
        property: 'span'
      })
    },
    updateSet(set) {
      this.$emit('updateElement', {
        elementId: this.element.id,
        id: set.id,
        type: this.element.type,
        property: 'set'
      })
    },
    updateScope(scope) {
      this.$emit('updateElement', {
        elementId: this.element.id,
        id: scope.id,
        type: this.element.type,
        property: 'scope'
      })
    },
    toggleEditListItem() {}
  }
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding-inline-start: 15px;
}

.multiselect-holder {
  width: 92%;
}
</style>
