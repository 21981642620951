<template>
  <div>
    <v-wait for="loadingVendorProducts">
      <template slot="waiting">
        <content-placeholders :rounded="true" class="loader">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>

      <LiquorTree
        :data="treeData"
        :multiple="false"
        :showChildren="true"
        :parentSelect="false"
        :options="treeOptions"
        ref="vendorProductsTree"
        @node:checked="nodeChecked"
        @node:unchecked="nodeUnChecked"
      >
        <span class="tree-text" slot-scope="{ node }">
          <template>
            <span :id="'ma-' + node.id.toString()">
              {{ node.text }}
            </span>
            <b-popover
              :target="'ma-' + node.id.toString()"
              triggers="hover"
              placement="right"
              boundary="window"
              v-if="node.data.description"
            >
              {{ node.data.description }}
            </b-popover>
          </template>
        </span>
      </LiquorTree>
    </v-wait>
  </div>
</template>

<script charset="utf-8">
//global
import { mapState, mapMutations } from 'vuex'
//ui
import LiquorTree from 'liquor-tree'

export default {
  name: 'VendorFit',
  created() {},
  components: {
    LiquorTree
  },
  props: {
    selectedReport: {
      type: Object,
      required: true
    },
    showOutput: {
      type: Boolean,
      default: true
    },
    renderedIn: {
      type: String,
      default: 'Report Writer'
    }
  },
  data() {
    return {
      treeData: null,
      searchText: null,
      treeOptions: {
        checkbox: true,
        checkOnSelect: false,
        parentSelect: false,
        autoCheckChildren: false
      }
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      elementsInfo: state => state.elementsInfo,
      reportElements: state => state.reportElements,
      vendorFitOptions: state => state.vendorFitOptions,
      elementSequenceID: state => state.elementSequenceID
    }),
    ...mapState('AdvancedQuery', {
      queryElements: state => state.queryElements
    }),
    segmentElementsInfo() {
      return this.elementsInfo.filter(ei => ei.segment === 'vendor_fit_static')
    }
  },
  methods: {
    ...mapMutations('ReportWriter', [
      'setVendorProducts',
      'addReportElement',
      'addCustomizedElement',
      'removeReportElement',
      'removeCustomizedElement',
      'updateSequenceID'
    ]),
    loadInitData() {
      this.prepareTreeData()
    },
    isElementAddedInReport(option) {
      return this.reportElements.some(
        re => re.type === 'vendor_fit_static' && re.property === option.property
      )
    },
    prepareTreeData() {
      this.treeData = this.vendorFitOptions.map(option => {
        let vfInfo = this.segmentElementsInfo.find(
          ei => ei.property === option.property
        )

        return {
          id: 1,
          text: option.name,
          data: {
            type: 'custom',
            property: option.property,
            description: vfInfo && vfInfo.description
          },
          state: {
            checked: this.isElementAddedInReport(option),
            disabled: false
          }
        }
      })

      this.$refs.vendorProductsTree.setModel(this.treeData)
    },
    addToReportElements(node) {
      let element = {
        leading: 1,
        name: node.text,
        type: 'vendor_fit_static',
        property: node.data.property,
        seqID: this.elementSequenceID
      }
      this.addReportElement(element)
      this.addCustomizedElement(element)
    },
    nodeChecked(node) {
      this.updateSequenceID()
      this.addToReportElements(node)
    },
    nodeUnChecked(node) {
      this.removeCustomizedElement({
        property: node.data.property,
        type: 'vendor_fit_static'
      })
      this.removeReportElement({
        property: node.data.property,
        type: 'vendor_fit_static'
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
