<template>
  <div class="report-element-customizer">
    <div v-if="customizedReportElements.length">
      <div class="text-muted text-right mb-2">
        <small class="font-weight-bold">
          (right click for format options; drag & drop to reorder)
        </small>
      </div>
      <VueNestable v-model="nestableElements">
        <template slot-scope="{ item }">
          <VueNestableHandle :item="item">
            <span v-if="item.type === 'element'" class="nestable-element">
              <span @contextmenu="openContextMenu($event, item)">
                <i class="fa fa-bars cursor-pointer" :id="`${item.id}`"></i>
                <span class="pl-1 cursor-pointer">
                  {{ item.text }}
                </span>
              </span>
            </span>
            <span v-else-if="item.type === 'space'" class="nestable-element">
              <span @contextmenu="openContextMenu($event, item)">
                <i class="fa fa-bars cursor-pointer" :id="`${item.id}`"></i>
                <small class="pl-1 text-muted">
                  (---SPACE---)
                </small>
              </span>
            </span>
            <span
              v-else-if="item.type === 'lineBreak'"
              class="nestable-element"
            >
              <span @contextmenu="openContextMenu($event, item)">
                <i class="fa fa-bars cursor-pointer" :id="`${item.id}`"></i>
                <small class="pl-1 text-muted">
                  (---LINE BREAK---)
                </small>
              </span>
            </span>
            <span v-else-if="item.type === 'header'" class="nestable-element">
              <span @contextmenu="openContextMenu($event, item)">
                <i class="fa fa-bars cursor-pointer" :id="`${item.id}`"></i>
                <span v-if="item.editable">
                  <input
                    type="text"
                    class="ml-2"
                    name=""
                    id=""
                    value=""
                    v-model="item.text"
                  />
                  <i
                    class="fa fa-check-circle fa-lg px-2 cursor-pointer success-green"
                    aria-hidden="true"
                    @click="setHeader(item)"
                  ></i>
                  <i
                    class="fa fa-times-circle fa-lg cursor-pointer secondary-color"
                    aria-hidden="true"
                    @click="resetHeader(item)"
                  ></i>
                </span>
                <span v-else>
                  <span class="font-weight-bold pl-2" v-if="item.text">{{
                    item.text
                  }}</span>
                  <small class="pl-1 text-muted" v-else>
                    (---HEADER---)
                  </small>
                </span>
              </span>
            </span>

            <span v-else-if="item.type === 'graph'" class="nestable-element">
              <span @contextmenu="openContextMenu($event, item)">
                <span v-if="item.editable">
                  <div class="row">
                    <div class="col-sm-1">
                      <i
                        class="fa fa-bars cursor-pointer"
                        :id="`${item.id}`"
                      ></i>
                    </div>
                    <div class="col-sm-8 px-1">
                      <multiselect
                        v-model="item.data.metrics"
                        :options="metricOptions"
                        :multiple="true"
                        :close-on-select="false"
                        label="name"
                        track-by="id"
                        :showLabels="false"
                        placeholder
                        class="simple-select"
                        :searchable="false"
                        :allow-empty="false"
                      >
                        <template slot="selection" slot-scope="{ values }"
                          >{{ values.length }}
                          {{ values.length | pluralize('metric') }}
                          selected</template
                        >
                      </multiselect>
                    </div>
                    <div class="col-sm-2 px-0">
                      <i
                        class="fa fa-check-circle fa-lg px-1 cursor-pointer success-green"
                        aria-hidden="true"
                        @click="setHeader(item)"
                      ></i>
                      <i
                        class="fa fa-times-circle fa-lg cursor-pointer secondary-color"
                        aria-hidden="true"
                        @click="resetHeader(item)"
                      ></i>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-11 offset-sm-1 px-1">
                      <small class=""> <b>*</b> 3 metrics per row </small>
                    </div>
                  </div>
                </span>
                <span v-else>
                  <i class="fa fa-bars cursor-pointer" :id="`${item.id}`"></i>
                  <span class="font-weight-bold pl-2" v-if="item.text">{{
                    item.text
                  }}</span>
                  <small class="pl-1 text-muted" v-else>
                    (---GRAPH---)
                  </small>
                  <i
                    class="fa fa-info-circle"
                    v-b-popover.hover="{
                      title: 'Selected Metrics',
                      content: getItemMetricsFormatted(item),
                      html: true
                    }"
                    title="Metrics"
                  ></i>
                </span>
              </span>
            </span>
          </VueNestableHandle>
        </template>
      </VueNestable>
      <ul
        id="context-menu"
        tabindex="-1"
        ref="contextMenu"
        v-if="viewContextMenu"
        @blur="closeContextMenu"
        v-bind:style="{ top: contextMenuTop, left: contextMenuLeft }"
      >
        <div v-if="selectedElement && selectedElement.type === 'element'">
          <li @click="addHeader" class="">ADD HEADER Above</li>
          <li @click="addSpace" class="">ADD SPACE Below</li>
          <li @click="addGraph" class="">ADD GRAPH Below</li>
          <li @click="addLineBreak" class="">ADD LINE BREAK Below</li>
          <li @click="moveUp" class="">Move Element Up</li>
          <li @click="moveDown" class="">Move Element Down</li>
          <li @click="indent" class="">ADD Indent</li>
          <li @click="unIndent" class="">DELETE Indent</li>
        </div>
        <div v-else-if="selectedElement && selectedElement.type === 'space'">
          <li @click="removeElement" class="">DELETE SPACE</li>
        </div>
        <div
          v-else-if="selectedElement && selectedElement.type === 'lineBreak'"
        >
          <li @click="removeElement" class="">DELETE LINE BREAK</li>
        </div>
        <div v-if="selectedElement && selectedElement.type === 'header'">
          <li @click="editHeader" class="">Edit HEADER</li>
          <li @click="removeElement" class="">DELETE HEADER</li>
        </div>
        <div v-if="selectedElement && selectedElement.type === 'graph'">
          <li @click="editGraph" class="">Edit GRAPH</li>
          <li @click="removeElement" class="">DELETE GRAPH</li>
        </div>
      </ul>
    </div>
    <div v-else>
      <h6 class="text-center text-muted p-4">
        No Report Elements added to customize.
      </h6>
    </div>
  </div>
</template>
<script charset="utf-8">
//global
import Vue from 'vue'
import { mapState, mapMutations } from 'vuex'
// ui components
import { VueNestable, VueNestableHandle } from 'vue-nestable'
import Multiselect from 'vue-multiselect'
// utilities
import deepClone from '@/utilities.js'

export default {
  name: 'OutputViewFormatter',
  components: {
    Multiselect,
    VueNestable,
    VueNestableHandle
  },
  data() {
    return {
      selectedElement: null,
      headerText: null,
      contextMenuTop: '0px',
      contextMenuLeft: '0px',
      viewContextMenu: false,
      metrics: []
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      reportElements: state => state.reportElements,
      elementSequenceID: state => state.elementSequenceID,
      customizedReportElements: state => state.customizedReportElements
    }),
    metricOptions() {
      return this.reportElements.filter(re =>
        ['metrics', 'common_metric'].includes(re.type)
      )
    },
    nestableElements: {
      get() {
        return this.customizedReportElements
      },
      set(elements) {
        this.updateCustomizedElements(elements)
      }
    }
  },
  methods: {
    ...mapMutations('ReportWriter', [
      'updateCustomizedElements',
      'updateSequenceID'
    ]),
    setContextMenu(top, left) {
      let largestHeight =
        window.innerHeight - this.$refs.contextMenu.offsetHeight - 25
      let largestWidth =
        window.innerWidth - this.$refs.contextMenu.offsetWidth - 25

      if (top > largestHeight) top = largestHeight
      if (left > largestWidth) left = largestWidth

      this.contextMenuTop = top + 'px'
      this.contextMenuLeft = left + 'px'
    },
    closeContextMenu() {
      this.viewContextMenu = false
    },
    openContextMenu(e, item) {
      this.selectedElement = item
      this.viewContextMenu = true

      this.$nextTick(
        function() {
          this.$refs.contextMenu.focus()
          this.setContextMenu(e.y, e.x)
        }.bind(this)
      )
      e.preventDefault()
    },
    addSpace() {
      this.updateSequenceID()
      let elementsTree = { children: deepClone(this.customizedReportElements) }
      this.addNewElement(elementsTree, this.selectedElement, 'space')
      this.updateCustomizedElements(elementsTree.children)
      this.closeContextMenu()
    },
    addLineBreak() {
      this.updateSequenceID()
      let elementsTree = { children: deepClone(this.customizedReportElements) }
      this.addNewElement(elementsTree, this.selectedElement, 'lineBreak')
      this.updateCustomizedElements(elementsTree.children)
      this.closeContextMenu()
    },
    addHeader() {
      this.updateSequenceID()
      let elementsTree = { children: deepClone(this.customizedReportElements) }
      this.addNewElement(elementsTree, this.selectedElement, 'header')
      this.updateCustomizedElements(elementsTree.children)
      this.closeContextMenu()
    },
    addGraph() {
      this.updateSequenceID()
      let elementsTree = { children: deepClone(this.customizedReportElements) }
      this.addNewElement(elementsTree, this.selectedElement, 'graph')
      this.updateCustomizedElements(elementsTree.children)
      this.closeContextMenu()
    },
    moveUp() {
      let elementsTree = { children: deepClone(this.customizedReportElements) }
      this.swapElement(elementsTree, this.selectedElement, 'up')
      this.updateCustomizedElements(elementsTree.children)
      this.closeContextMenu()
    },
    moveDown() {
      let elementsTree = { children: deepClone(this.customizedReportElements) }
      this.swapElement(elementsTree, this.selectedElement, 'down')
      this.updateCustomizedElements(elementsTree.children)
      this.closeContextMenu()
    },
    indent() {
      let elements = deepClone(this.customizedReportElements)
      this.indentElement(elements, this.selectedElement)
      this.updateCustomizedElements(elements)
      this.closeContextMenu()
    },
    unIndent() {
      let elementTree = { children: deepClone(this.customizedReportElements) }
      this.unIndentElement(elementTree, null, this.selectedElement)
      this.updateCustomizedElements(elementTree.children)
      this.closeContextMenu()
    },
    removeElement() {
      let elementsTree = { children: deepClone(this.customizedReportElements) }
      this.removeItem(elementsTree, this.selectedElement)
      this.updateCustomizedElements(elementsTree.children)
      this.closeContextMenu()
    },
    editHeader() {
      this.selectedElement.editable = true
      this.headerText = this.selectedElement.text
      this.closeContextMenu()
    },
    editGraph() {
      this.selectedElement.editable = true
      this.closeContextMenu()
    },
    setHeader(item) {
      item.editable = false
    },
    resetHeader(item) {
      item.text = this.headerText || ''
      item.editable = false
    },
    indentElement(elements, targetElement) {
      let targetIndex = elements.findIndex(ce => ce.id === targetElement.id)

      if (targetIndex !== -1) {
        if (targetIndex !== 0) {
          let parentElement = elements[targetIndex - 1]
          let childElement = elements[targetIndex]

          elements.splice(targetIndex, 1)
          parentElement.children.push(childElement)
        } else {
          Vue.toasted.show('Operation invalid. Cannot be performed.', {
            icon: 'chain-broken',
            type: 'error'
          })
        }
      } else {
        elements.forEach(el => this.indentElement(el.children, targetElement))
      }
    },
    unIndentElement(element, parentElement, targetElement) {
      if (element.children && element.children.length) {
        let targetIndex = element.children.findIndex(
          ce => ce.id === targetElement.id
        )

        if (targetIndex !== -1) {
          if (parentElement) {
            let sourceElementIndex = parentElement.children.findIndex(
              ce => ce.id === element.id
            )

            parentElement.children.splice(
              sourceElementIndex + 1,
              0,
              element.children[targetIndex]
            )
            element.children.splice(targetIndex, 1)
          } else {
            Vue.toasted.show('Operation invalid. Cannot be performed.', {
              icon: 'chain-broken',
              type: 'error'
            })
          }
        } else {
          element.children.forEach(childEl =>
            this.unIndentElement(childEl, element, targetElement)
          )
        }
      }
    },
    addNewElement(element, targetElement, type) {
      if (element.children && element.children.length) {
        let targetIndex = element.children.findIndex(
          ce => ce.id === targetElement.id
        )

        if (targetIndex !== -1) {
          let newIndex = type === 'header' ? targetIndex : targetIndex + 1

          element.children.splice(newIndex, 0, {
            id: this.elementSequenceID,
            type: type,
            text: ['header', 'graph'].includes(type) ? '' : 'Blank Line',
            editable: ['header', 'graph'].includes(type),
            data: {},
            children: []
          })
        } else {
          element.children.forEach(ce =>
            this.addNewElement(ce, targetElement, type)
          )
        }
      }
    },
    swapElement(element, targetElement, type) {
      if (element.children && element.children.length) {
        let targetIndex = element.children.findIndex(
          ce => ce.id === targetElement.id
        )

        if (targetIndex !== -1) {
          if (
            (type === 'up' && targetIndex === 0) ||
            (type === 'down' && targetIndex === element.children.length - 1)
          ) {
            Vue.toasted.show('Operation invalid. Cannot be performed.', {
              icon: 'chain-broken',
              type: 'error'
            })
          }

          let newIndex = type === 'up' ? targetIndex - 1 : targetIndex + 1

          element.children.splice(targetIndex, 1)
          element.children.splice(newIndex, 0, targetElement)
        } else {
          element.children.forEach(ce =>
            this.swapElement(ce, targetElement, type)
          )
        }
      }
    },
    removeItem(element, targetElement) {
      if (element.children && element.children.length) {
        let targetIndex = element.children.findIndex(
          ce => ce.id === targetElement.id
        )

        if (targetIndex !== -1) {
          element.children.splice(targetIndex, 1)
          if (targetElement.children) {
            element.children.splice(targetIndex, 0, ...targetElement.children)
          }
        } else {
          element.children.forEach(ce => this.removeItem(ce, targetElement))
        }
      }
    },
    getItemMetricsFormatted(item) {
      let metrics = item.data.metrics || []
      let metricsText = metrics.length
        ? 'Below are the metrics selected.<br/>'
        : 'No metrics selected.'
      metrics.forEach((metric, index) => {
        metricsText += `<b>${index + 1}</b>. ${metric.name} <br/>`
      })
      return metricsText
    }
  }
}
</script>

<style lang="scss">
.nestable {
  position: relative;
}

.nestable-rtl {
  direction: rtl;
}

.nestable .nestable-list {
  margin: 0;
  padding: 0 0 0 40px;
  list-style-type: none;
}

.nestable-rtl .nestable-list {
  padding: 0 20px 0 0;
}

.nestable > .nestable-list {
  padding: 0;
}

.nestable-item,
.nestable-item-copy {
  margin: 10px 0 0;
}

.nestable-item:first-child,
.nestable-item-copy:first-child {
  margin-top: 0;
}

.nestable-item .nestable-list,
.nestable-item-copy .nestable-list {
  margin-top: 10px;
}

.nestable-item {
  position: relative;
}

.nestable-item.is-dragging .nestable-list {
  pointer-events: none;
}

.nestable-item.is-dragging * {
  opacity: 0;
  filter: alpha(opacity=0);
}

.nestable-item.is-dragging:before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(106, 127, 233, 0.274);
  border: 1px dashed rgb(73, 100, 241);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.nestable-drag-layer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;
}

.nestable-rtl .nestable-drag-layer {
  left: auto;
  right: 0;
}

.nestable-drag-layer > .nestable-list {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  background-color: rgba(106, 127, 233, 0.274);
}

.nestable-rtl .nestable-drag-layer > .nestable-list {
  padding: 0;
}

.nestable [draggable='true'] {
  cursor: move;
}

.nestable-handle {
  display: inline;
}

.formatter-menu-list {
  min-width: 10rem;
}

.nestable-element {
  width: 100%;
  height: 100%;
}

#context-menu {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 5px;
  position: fixed;
  width: 200px;
  z-index: 999999;
  background: #fafafa;
  border: 1px solid #bdbdbd;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

#context-menu li {
  border-bottom: 1px solid #e0e0e0;
  margin: 0;
  padding: 5px 20px;
}

#context-menu li:last-child {
  border-bottom: none;
}

#context-menu li:hover {
  background: #1e88e5;
  color: #fafafa;
}
</style>
