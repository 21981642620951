<template>
  <div class="report-output-view">
    <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Output" active>
            <template #title>
              <h6 class="mb-0 d-inline-block">
                <b>Output</b>
              </h6>
            </template>
            <b-card-text>
              <div v-if="hasNoElements">
                <h6 class="text-center text-muted p-4">
                  No Report Elements added.
                </h6>
              </div>
              <div v-else>
                <!-- <div class="row" v-if="outputEditable">
                  <div class="col-sm-12 text-right">
                    <h6 class="mb-0 pr-2 d-inline-block">
                      Edit
                    </h6>
                    <c-switch
                      type="text"
                      variant="success"
                      on="Yes"
                      off="No"
                      :pill="true"
                      size="sm"
                      :checked="editable"
                      @change="toggleEditMode"
                      class="mb-0 mt-1"
                    ></c-switch>
                  </div>
                </div> -->
                <div>
                  <ul class="output-groups" v-if="outputEditable">
                    <li v-if="showDefaultElements">
                      <div
                        @click="
                          defaultElementsExpanded = !defaultElementsExpanded
                        "
                        class="mt-1"
                      >
                        <span class="pr-1 font-weight-bold">
                          {{ defaultElementsExpanded ? '-' : '+' }}
                        </span>
                        <h6 class="d-inline-block font-weight-bold mb-1">
                          Default
                        </h6>
                      </div>
                      <ul class="group-items" v-if="defaultElementsExpanded">
                        <li
                          v-for="(element, index) in defaultOptionsToDisplay"
                          :key="index"
                        >
                          <span class="font-weight-bold pr-1">-</span>
                          {{ element.name }}
                        </li>

                        <li
                          class="font-weight-bold pt-1"
                          v-if="actualQueryElements.length"
                        >
                          Query Elements
                        </li>
                        <li
                          v-for="(element, index) in actualQueryElements"
                          :key="`qe_${index}`"
                          :class="[
                            element === selectedQueryElement ? 'selected' : '',
                            'pb-1 pl-3'
                          ]"
                        >
                          <div
                            v-if="element.type === 'technographics'"
                            @click="toggleQueryElement(element)"
                          >
                            <span class="font-weight-bold pr-1">{{
                              selectedQueryElement === element ? '-' : '+'
                            }}</span>
                            {{ element.name }}
                            <ul
                              class="group-items"
                              v-if="selectedQueryElement === element"
                            >
                              <li class="pt-1">
                                <span class="font-weight-bold pr-1">-</span>
                                Default
                              </li>
                            </ul>
                          </div>
                          <div
                            v-else-if="
                              [
                                'metrics',
                                'common_metric',
                                'financial_statements'
                              ].includes(element.type)
                            "
                            @click="toggleQueryElement(element)"
                          >
                            <span class="font-weight-bold pr-1">{{
                              selectedQueryElement === element ? '-' : '+'
                            }}</span>
                            {{ element.name.replace('+', '').trim() }}
                            <ul
                              class="group-items"
                              v-if="selectedQueryElement === element"
                            >
                              <li class="pt-1">
                                <span class="font-weight-bold pr-1">-</span>
                                Metric Value
                              </li>
                              <li class="pt-1">
                                <span class="font-weight-bold pr-1">-</span>
                                As Reported
                              </li>
                              <li class="pt-1">
                                <span class="font-weight-bold pr-1">-</span>
                                Trailing-12 Months
                              </li>
                              <li class="pt-1">
                                <span class="font-weight-bold pr-1">-</span>
                                Current Period
                              </li>
                            </ul>
                          </div>
                          <div v-else>
                            <span class="font-weight-bold pr-1">-</span>
                            {{ element.name }}
                          </div>
                        </li>
                      </ul>
                    </li>
                    <li v-if="customReportElements.length">
                      <div
                        @click="
                          customElementsExpanded = !customElementsExpanded
                        "
                        class="mt-2"
                      >
                        <span class="pr-1 font-weight-bold">
                          {{ customElementsExpanded ? '-' : '+' }}
                        </span>
                        <h6 class="d-inline-block font-weight-bold mb-1">
                          Custom
                        </h6>
                      </div>
                      <ul class="group-items" v-if="customElementsExpanded">
                        <li
                          v-for="(element, index) in customReportElements"
                          :key="index"
                          :class="[
                            element === selectedCustomElement ? 'selected' : '',
                            'pb-1'
                          ]"
                        >
                          <div v-if="elementSelectable(element)">
                            <div @click="toggleCustomElement(element)">
                              <span class="font-weight-bold pr-1">
                                {{
                                  selectedCustomElement === element ? '-' : '+'
                                }}
                              </span>

                              {{
                                element.type === 'vendor_fit_static'
                                  ? 'VendorFit : '
                                  : ''
                              }}
                              {{ element.name }}
                            </div>

                            <keep-alive>
                              <component
                                v-bind:is="`${elementType(element)}-options`"
                                :element="element"
                                :editable="isEditable(element)"
                                :fiType="
                                  duplicateExists(element)
                                    ? getFiType(element)
                                    : ''
                                "
                                @updateElement="updateCustomElement"
                                v-if="selectedCustomElement === element"
                              ></component>
                            </keep-alive>
                          </div>
                          <div v-else>
                            <span class="font-weight-bold pr-1"> - </span>
                            {{ element.name }}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                  <ul class="output-groups" v-else>
                    <li v-if="irReportElements.length">
                      <div class="mt-2">
                        <span class="pr-1 font-weight-bold">
                          -
                        </span>
                        <h6 class="d-inline-block font-weight-bold mb-1">
                          Custom
                        </h6>
                      </div>
                      <ul class="group-items">
                        <li
                          v-for="(element, index) in irReportElements"
                          :key="index"
                          :class="[
                            element === selectedCustomElement ? 'selected' : '',
                            'pb-1'
                          ]"
                        >
                          <div>
                            <span class="font-weight-bold pr-1">-</span>
                            {{ element.name }}
                          </div>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </b-card-text>
          </b-tab>
          <b-tab title="Format" v-if="isCustomizable">
            <template #title>
              <h6 class="mb-0 d-inline-block">
                <b>Format</b>
              </h6>
            </template>
            <b-card-text>
              <output-view-formatter></output-view-formatter>
            </b-card-text>
          </b-tab>
          <b-tab
            title="Global Settings"
            v-if="outputEditable && customReportElements.length"
          >
            <template #title>
              <h6 class="mb-0 d-inline-block">
                <b>Global Settings</b>
              </h6>
            </template>
            <b-card-text>
              <keep-alive>
                <global-settings :renderedIn="renderedIn"></global-settings>
              </keep-alive>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
//global
import { mapState, mapGetters, mapMutations } from 'vuex'
//ui components
import cSwitch from '@/components/Switch'
import TechnographicsOptions from './technographicsOptions'
import metricsOptions from './metricsOptions'
import OutputViewFormatter from './OutputViewFormatter'
import vendorFitOptions from './vendorFitOptions'
import GlobalSettings from './GlobalSettings.vue'
//module utilities
import { validQueryElements } from '@/modules/advanced_query/helpers/utilities.js'
import axios from 'axios'
import _ from 'lodash'
import financialStatementsAPI from '@/api/finapps/financial_statements'

export default {
  name: 'OutputViewCard',
  props: {
    outputEditable: {
      type: Boolean,
      default: true
    },
    renderedIn: {
      type: String,
      default: 'ReportWriter'
    }
  },
  data() {
    return {
      defaultElementsExpanded: true,
      customElementsExpanded: true,
      selectedQueryElement: null,
      selectedCustomElement: null,
      editable: false
    }
  },
  components: {
    metricsOptions,
    vendorFitOptions,
    TechnographicsOptions,
    OutputViewFormatter,
    GlobalSettings,
    cSwitch
  },
  computed: {
    ...mapState('ReportWriter', {
      defaultOptions: state => state.defaultOptions,
      firmographicMBCDefaultOptions: state =>
        state.firmographicMBCDefaultOptions,
      reportElements: state => state.reportElements,
      metricTreeData: state => state.metricTreeData,
      financialStatementsData: state => state.financialStatementsData
    }),
    ...mapState('AdvancedQuery', {
      queryElements: state => state.queryElements
    }),
    ...mapGetters('AdvancedQuery', ['isMortgageLender']),
    ...mapGetters('ReportWriter', [
      'reportElementsWithNoDefaults',
      'globalSettings'
    ]),
    actualQueryElements() {
      return validQueryElements(
        ['FIN Query'].includes(this.renderedIn) && this.isMortgageLender
      )
    },
    irReportElements() {
      return this.reportElements.filter(re =>
        [
          'metrics',
          'common_metric',
          'technographics',
          'financial_statements',
          'common_financial_statements'
        ].includes(re.type)
      )
    },
    isCustomizable() {
      return ['Report Writer', 'FIN Reporter'].includes(this.renderedIn)
    },
    showDefaultElements() {
      return !['Industry Reporter', 'FIN Reporter', 'Report Writer'].includes(
        this.renderedIn
      )
    },
    hasNoElements() {
      return this.reportElements.length === 0 && !this.showDefaultElements
    },
    customReportElements() {
      return this.showDefaultElements
        ? this.reportElementsWithNoDefaults
        : this.reportElements
    },
    defaultOptionsToDisplay() {
      return ['FIN Query'].includes(this.renderedIn) && this.isMortgageLender
        ? this.firmographicMBCDefaultOptions
        : this.defaultOptions
    }
  },
  methods: {
    ...mapMutations('ReportWriter', [
      'updateReportElement',
      'setMetricTreeData',
      'setFinancialCommonStatementsData',
      'setFinancialStatementsData'
    ]),
    elementType(element) {
      return [
        'common_metric',
        'financial_statements',
        'common_financial_statements'
      ].includes(element.type)
        ? 'metrics'
        : element.type === 'vendor_fit_static'
        ? 'vendor-fit'
        : element.type
    },
    isEditable(element) {
      return ['financial_statements', 'common_financial_statements'].includes(
        element.type
      ) && this.globalSettings['Financial Statements'] === 'true'
        ? false
        : ['common_metric', 'metrics'].includes(element.type) &&
          this.globalSettings['Financial Metrics'] === 'true'
        ? false
        : element.type === 'technographics' &&
          this.globalSettings['Technographics'] === 'true'
        ? false
        : this.outputEditable
    },

    toggleQueryElement(element) {
      if (this.selectedQueryElement === element) {
        this.selectedQueryElement = null
      } else {
        this.selectedQueryElement = element
      }
    },
    toggleCustomElement(element) {
      if (this.selectedCustomElement === element) {
        this.selectedCustomElement = null
      } else {
        this.selectedCustomElement = element
      }
    },
    toggleEditMode(val) {
      this.editable = val
    },
    updateCustomElement(options) {
      this.updateReportElement(options)
      this.selectedCustomElement = this.reportElements.find(
        el => el.id === options.elementId && el.type === options.type
      )
    },
    elementSelectable(element) {
      return [
        'metrics',
        'vendor_fit_static',
        'common_metric',
        'technographics',
        'financial_statements',
        'common_financial_statements'
      ].includes(element.type)
    },
    duplicateExists(element) {
      let elementsWithSameName = this.reportElements.filter(
        el => el.name === element.name
      )
      return elementsWithSameName.length > 1
    },
    getFiType(element) {
      if (
        ['common_metric', 'common_financial_statements'].includes(element.type)
      )
        return 'Bank & CU'
      else if (element.id == '1931') {
        return 'Bank'
      } else if (element.id == '10014') {
        return 'CU'
      } else if (element.type === 'metrics') {
        if (Object.keys(this.metricTreeData).length) {
          return this.checkMetricType(element)
        } else {
          axios
            .get('/api/metric_tree_relationships/tree_relationships', {
              handleErrors: true,
              params: {}
            })
            .then(res => {
              this.setMetricTreeData(res.data.relationships)
              return this.checkMetricType(element)
            })
        }
      } else if (element.type === 'financial_statements') {
        if (this.financialStatementsData.length) {
          return this.checkStatementType(element)
        } else {
          return financialStatementsAPI.all().then(res => {
            this.setFinancialCommonStatementsData(res.common_statements)
            this.setFinancialStatementsData(res.statements)
            return this.checkStatementType(element)
          })
        }
      } else return 'Metric id- ' + element.id
    },
    checkMetricType(element) {
      if (
        this.metricTreeData[3].find(metric => metric.metric_id == element.id)
      ) {
        return 'CU'
      } else return 'Bank'
    },
    checkStatementType(element) {
      let cuStatementIDsSet = new Set()
      this.statementsByFIType()['credit_union'].forEach(fs =>
        this.collectStatementIDs(fs, cuStatementIDsSet)
      )
      if (cuStatementIDsSet.has(element.id)) return 'CU'
      else return 'Bank'
    },
    statementsByFIType() {
      return _.groupBy(this.financialStatementsData, s => s.fi_type)
    },

    collectStatementIDs(statement, statementIDsSet) {
      if (statement.children && statement.children.length) {
        if (statement.element_id) {
          statementIDsSet.add(statement.element_id)
        }
        statement.children.forEach(child =>
          this.collectStatementIDs(child, statementIDsSet)
        )
      } else if (statement.tree) {
        this.collectStatementIDs(statement.tree, statementIDsSet)
      } else {
        if (statement.element_id) {
          statementIDsSet.add(statement.element_id)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.report-output-view {
  li {
    font-size: 13px;
    cursor: pointer;

    &.selected {
      background-color: #f0f3f5;

      ul {
        display: block;
      }
    }
  }

  ul {
    &.group-items {
      li {
        &:hover {
          background-color: #f0f3f5;
        }
      }
    }
  }

  .view-header {
    .active {
      background-color: white;
      transition: 1s;
      cursor: pointer;
    }

    .inactive {
      background-color: #c9c5c5;
      cursor: pointer;
    }
  }
}

ul.group-items {
  list-style: none;
  padding-inline-start: 10px;
}

ul.output-groups {
  list-style: none;
  padding-inline-start: 0;
}
</style>

<style lang="scss">
.report-output-view {
  .switch.switch-text.switch-sm {
    height: 16px;

    .switch-handle {
      height: 12px;
    }
  }

  .tabs {
    ul.nav-tabs {
      margin-top: 1px;

      li.nav-item {
        min-width: 6rem;

        a {
          padding: 0.5rem 0.5rem;
          text-align: center;
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;

          &.active {
            border-top: 1px solid #a4b7c1;
          }

          &:hover {
            border-top: 1px solid #a4b7c1;
          }
        }
      }
    }
  }
}
</style>
