<template>
  <div>
    <ul class="">
      <li class="pt-1" v-if="!isFINQueryMortgageLender">
        <b>Financial Metrics</b>
      </li>
      <li class="pt-1 pl-2" v-if="!isFINQueryMortgageLender">
        <b-form-checkbox
          id="setAsGlobalFinMetrics"
          name="Set options for all metrics"
          ref="setAsGlobalFinMetrics"
          class=" rounded d-inline b-0 pr-2 mb-1"
          v-model="globalSettings['Financial Metrics']"
          @change="setGlobalSettings(globalSettings)"
          value="true"
          unchecked-value="false"
        >
          <span class="col-form-label">Set options for all metrics</span>
        </b-form-checkbox>
      </li>
      <li class="pt-1 pl-3">
        <div v-if="isGlobalFinMetrics">
          <span class="font-weight-bold pr-1">-</span>
          <span class="multiselect-holder d-inline-block">
            <Multiselect
              track-by="id"
              label="text"
              :allowEmpty="false"
              :showLabels="false"
              :options="setOptions"
              v-model="globalFinMetrics['set']"
              class="rw-multi-select"
              @select="setGlobalFinMetrics(globalFinMetrics)"
            ></Multiselect>
          </span>
        </div>
      </li>
      <li class="pt-1  pl-3">
        <div v-if="isGlobalFinMetrics">
          <span class="font-weight-bold pr-1">-</span>
          <span class="multiselect-holder d-inline-block">
            <Multiselect
              track-by="id"
              label="text"
              :allowEmpty="false"
              :showLabels="false"
              :options="spanOptions"
              v-model="globalFinMetrics['span']"
              class="rw-multi-select"
              @select="setGlobalFinMetrics(globalFinMetrics)"
            ></Multiselect>
          </span>
        </div>
      </li>
      <li class="pt-1 pl-3">
        <div v-if="isGlobalFinMetrics">
          <span class="font-weight-bold pr-1">-</span>
          <span class="multiselect-holder d-inline-block">
            <Multiselect
              track-by="id"
              label="text"
              :allowEmpty="false"
              :showLabels="false"
              :options="scopeOptions"
              v-model="globalFinMetrics['scope']"
              class="rw-multi-select"
              @select="setGlobalFinMetrics(globalFinMetrics)"
            ></Multiselect>
          </span>
        </div>
      </li>
      <li class="pt-1" v-if="!isFINQueryMortgageLender">
        <b>Financial Statements</b>
      </li>
      <li class="pt-1 pl-2" v-if="!isFINQueryMortgageLender">
        <b-form-checkbox
          id="setAsGlobalFinStatements"
          name="Set options for all statements"
          ref="setAsGlobalFinStatements"
          class=" rounded d-inline b-0 pr-2 mb-1"
          v-model="globalSettings['Financial Statements']"
          @change="setGlobalSettings(globalSettings)"
          value="true"
          unchecked-value="false"
        >
          <span class="col-form-label">Set options for all line items</span>
        </b-form-checkbox>
      </li>
      <li class="pt-1 pl-3">
        <div v-if="isGlobalFinStatements">
          <span class="font-weight-bold pr-1">-</span>
          <span class="multiselect-holder d-inline-block">
            <Multiselect
              track-by="id"
              label="text"
              :allowEmpty="false"
              :showLabels="false"
              :options="setOptions"
              v-model="globalFinStatements['set']"
              class="rw-multi-select"
              @select="setGlobalFinStatements(globalFinStatements)"
            ></Multiselect>
          </span>
        </div>
      </li>
      <li class="pt-1  pl-3">
        <div v-if="isGlobalFinStatements">
          <span class="font-weight-bold pr-1">-</span>
          <span class="multiselect-holder d-inline-block">
            <Multiselect
              track-by="id"
              label="text"
              :allowEmpty="false"
              :showLabels="false"
              :options="spanOptions"
              v-model="globalFinStatements['span']"
              class="rw-multi-select"
              @select="setGlobalFinStatements(globalFinStatements)"
            ></Multiselect>
          </span>
        </div>
      </li>
      <li class="pt-1 pl-3">
        <div v-if="isGlobalFinStatements">
          <span class="font-weight-bold pr-1">-</span>
          <span class="multiselect-holder d-inline-block">
            <Multiselect
              track-by="id"
              label="text"
              :allowEmpty="false"
              :showLabels="false"
              :options="scopeOptions"
              v-model="globalFinStatements['scope']"
              class="rw-multi-select"
              @select="setGlobalFinStatements(globalFinStatements)"
            ></Multiselect>
          </span>
        </div>
      </li>
      <li class="pt-1">
        <b>Technographics</b>
      </li>
      <li class="pt-1 pl-2">
        <b-form-checkbox
          id="setAsGlobalTechnographics"
          name="Set options for all Technographics"
          ref="setAsGlobalTechnographics"
          class=" rounded d-inline b-0 pr-2 mb-1"
          v-model="globalSettings['Technographics']"
          @change="setGlobalSettings(globalSettings)"
          value="true"
          unchecked-value="false"
        >
          <span class="col-form-label">Set options for all technographics</span>
        </b-form-checkbox>
      </li>
      <li class="pt-1 pl-3">
        <div v-if="isGlobalTechnographics">
          <span class="font-weight-bold pr-1">-</span>
          <span class="multiselect-holder d-inline-block">
            <Multiselect
              track-by="id"
              label="text"
              :allowEmpty="false"
              :showLabels="false"
              :options="propertyOptions"
              v-model="globalTechnographics['property']"
              class="rw-multi-select"
              @input="setGlobalTechnographics(globalTechnographics)"
              :multiple="true"
            >
              <template
                slot="selection"
                v-if="globalTechnographics['property'].length"
                slot-scope="{ values }"
              >
                {{ values.length }}
                {{ values.length | pluralize('option') }} selected
              </template>
            </Multiselect>
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
//global
import { mapGetters, mapMutations } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  name: 'GlobalSettings',
  props: {
    renderedIn: {
      type: String,
      default: 'ReportWriter'
    }
  },
  components: {
    Multiselect
  },
  data() {
    return {
      defaultSet: 1,
      defaultSpan: 2,
      defaultScope: 'single',
      setOptions: [
        { id: 1, text: 'As Reported' },
        { id: 2, text: 'Merger Restated' }
      ],
      spanOptions: [
        { id: 1, text: 'Quarterly' },
        { id: 2, text: 'Trailing-12 Months' },
        { id: 3, text: 'Yearly' }
      ],
      scopeOptions: [
        { id: 'trend', text: '5-Period Trend' },
        { id: 'single', text: 'Current Period' }
      ],
      propertyOptions: [
        { id: 'default', text: 'Default' },
        { id: 'has_service', text: 'Has Service' },
        { id: 'vendor', text: 'Vendor' },
        { id: 'product', text: 'Product' }
      ]
    }
  },
  computed: {
    ...mapGetters('ReportWriter', [
      'globalSettings',
      'globalFinMetrics',
      'globalFinStatements',
      'globalTechnographics'
    ]),
    ...mapGetters('AdvancedQuery', ['isMortgageLender']),
    isGlobalFinMetrics() {
      return (
        this.globalSettings['Financial Metrics'] === 'true' &&
        !this.isFINQueryMortgageLender
      )
    },
    isGlobalFinStatements() {
      return (
        this.globalSettings['Financial Statements'] === 'true' &&
        !this.isFINQueryMortgageLender
      )
    },
    isGlobalTechnographics() {
      return this.globalSettings['Technographics'] === 'true'
    },
    isFINQueryMortgageLender() {
      return this.renderedIn === 'FIN Query' && this.isMortgageLender
    }
  },
  methods: {
    ...mapMutations('ReportWriter', [
      'setGlobalSettings',
      'setGlobalFinMetrics',
      'setGlobalFinStatements',
      'setGlobalTechnographics'
    ]),

    toggleEditListItem() {}
  }
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding-inline-start: 15px;
}

.multiselect-holder {
  width: 92%;
}
</style>
