<template>
  <div>
    <v-wait for="loadingContactDetails">
      <template slot="waiting">
        <content-placeholders :rounded="true" class="loader">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div class="row p-2">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-sm-8">
              <div class="row">
                <div class="col-sm-5">
                  <label for="" class="px-2">Include FI Contacts:</label>
                </div>
                <div class="col-sm-4">
                  <c-switch
                    type="text"
                    variant="success"
                    on="Yes"
                    off="No"
                    :pill="true"
                    size="sm"
                    v-model="includeContacts"
                    class="mb-0"
                  ></c-switch>
                </div>
              </div>
            </div>
            <div class="col-sm-4 text-right">
              <label for="" class="px-3"><b>Total Contacts:</b></label>
              <span class="contact-count">
                <v-wait for="fetchingContactCount">
                  <template slot="waiting">
                    <i class="fa fa-lg fa-spinner fa-spin"></i>
                  </template>
                  <span v-if="fiContactCount && includeContacts">
                    {{ fiContactCount | numberFormat }}
                  </span>
                  <span v-else>NA</span>
                </v-wait>
              </span>
            </div>
          </div>

          <b-card
            class="std-border mt-2 mb-3 contact-options-card"
            body-class="p-2"
            header-class=""
          >
            <template #header>
              <div class="row">
                <div class="col-sm-6">
                  <h6 class="mb-0 font-weight-bold">Filters</h6>
                </div>
                <div class="col-sm-6 text-right" v-if="!includeContacts">
                  <small class="text-muted"
                    >Enable FI Contacts to add filters.</small
                  >
                </div>
              </div>
            </template>
            <div>
              <div :class="{ 'tag-disabled': !includeContacts }">
                <div class="row pt-2">
                  <div class="col-sm-8">
                    <div class="row">
                      <div class="col-sm-6">
                        <label for="" class="px-2"
                          >Exclude FIs without Contacts:</label
                        >
                      </div>
                      <div class="col-sm-6">
                        <c-switch
                          type="text"
                          variant="success"
                          on="Yes"
                          off="No"
                          :pill="true"
                          size="sm"
                          v-model="excludeFIs"
                          class="mb-0"
                        ></c-switch>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row py-2">
                  <div class="col-sm-10">
                    <div class="row">
                      <div class="col-sm-5">
                        <label for="" class="px-2">Has Email:</label>
                      </div>
                      <div class="col-sm-7 pl-0">
                        <multiselect
                          v-model="hasEmail"
                          :options="emailOptions"
                          :multiple="false"
                          :close-on-select="true"
                          :showLabels="false"
                          track-by="id"
                          label="name"
                          placeholder="Select one"
                          :class="['simple-select']"
                        >
                        </multiselect>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row py-2">
                  <div class="col-sm-10">
                    <div class="row">
                      <div class="col-sm-5">
                        <label for="" class="px-2">Titles:</label>
                      </div>
                      <div class="col-sm-7 pl-0">
                        <treeselect
                          v-model="titles"
                          :multiple="true"
                          :options="formattedTitles"
                          :limit="1"
                          :clearable="false"
                          placeholder="Select one or more"
                          @close="submitFilterSelection"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row py-2">
                  <div class="col-sm-10">
                    <div class="row">
                      <div class="col-sm-5">
                        <label for="" class="px-2">Roles:</label>
                      </div>
                      <div class="col-sm-7 pl-0">
                        <treeselect
                          v-model="roles"
                          :multiple="true"
                          :options="formattedRoles"
                          :limit="1"
                          :clearable="false"
                          placeholder="Select one or more"
                          @close="submitFilterSelection"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </div>
      </div>
    </v-wait>
  </div>
</template>

<script charset="utf-8">
// global
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
//ui components
import cSwitch from '@/components/Switch'
import Multiselect from 'vue-multiselect'
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import '@/plugins/css/vue-treeselect-overrides.css'
// api
import advancedQueryAPI from '@/modules/advanced_query/api/advanced.query'
import reportWriterElementsAPI from '@/api/finapps/report_writer_elements'
// helpers
import QueryTransformer from '../../advanced_query/helpers/query.transformer.js'

export default {
  name: 'FIContacts',
  components: {
    cSwitch,
    Multiselect,
    Treeselect
  },
  data() {
    return {
      emailOptions: [
        { id: 'yes', name: 'Yes' },
        { id: 'no', name: 'No' },
        { id: 'both', name: 'Both' }
      ],
      // define the default value
      value: null,
      // define options
      options: [
        {
          id: 'a',
          label: 'a',
          children: [
            {
              id: 'aa',
              label: 'aa'
            },
            {
              id: 'ab',
              label: 'ab'
            }
          ]
        },
        {
          id: 'b',
          label: 'b'
        },
        {
          id: 'c',
          label: 'c'
        }
      ]
    }
  },
  computed: {
    ...mapState('AdvancedQuery', {
      fiContactCount: state => state.fiContactCount
    }),
    ...mapGetters('AdvancedQuery', ['isMortgageLender']),
    ...mapState('ReportWriter', {
      reportElements: state => state.reportElements,
      contactTitles: state => state.contactTitles,
      contactRoles: state => state.contactRoles
    }),
    formattedTitles() {
      return this.contactTitles.map(title => this.formatTitle(title))
    },
    formattedRoles() {
      return this.contactRoles.map(role => this.formatRole(role))
    },
    includeContacts: {
      get() {
        return this.contactElement ? true : false
      },
      set(includeContact) {
        if (includeContact) {
          let element = {
            name: 'FI Contacts',
            type: 'fi_contacts',
            email: 'both',
            excludeFI: false,
            property: 'include_contacts',
            titles: [],
            roles: []
          }

          this.addReportElement(element)
          this.fetchTotalContacts({})
        } else {
          this.removeElementByProperty({
            property: 'include_contacts',
            type: 'fi_contacts'
          })
        }
      }
    },
    hasEmail: {
      get() {
        return this.contactElement
          ? this.emailOptions.find(eo => eo.id === this.contactElement.email)
          : this.emailOptions.find(eo => eo.id === 'both')
      },
      set(email) {
        this.updateReportElementByProperty({
          type: 'fi_contacts',
          email: email.id,
          titles: this.titles,
          roles: this.roles,
          property: 'include_contacts',
          excludeFI: this.contactElement.excludeFI
        })
        this.fetchTotalContacts({})
      }
    },
    excludeFIs: {
      get() {
        return this.contactElement ? this.contactElement.excludeFI : false
      },
      set(excludeFI) {
        this.updateReportElementByProperty({
          type: 'fi_contacts',
          excludeFI: excludeFI,
          property: 'include_contacts',
          email: this.contactElement.email,
          titles: this.titles,
          roles: this.roles
        })
        this.fetchTotalContacts({})
      }
    },
    titles: {
      get() {
        return this.contactElement ? this.contactElement.titles : []
      },
      set(titles) {
        this.updateReportElementByProperty({
          type: 'fi_contacts',
          titles: titles,
          roles: this.roles,
          email: this.contactElement.email,
          property: 'include_contacts',
          excludeFI: this.contactElement.excludeFI
        })
        // this.fetchTotalContacts({})
      }
    },
    roles: {
      get() {
        return this.contactElement ? this.contactElement.roles : []
      },
      set(roles) {
        this.updateReportElementByProperty({
          type: 'fi_contacts',
          titles: this.titles,
          roles: roles,
          email: this.contactElement.email,
          property: 'include_contacts',
          excludeFI: this.contactElement.excludeFI
        })
        // this.fetchTotalContacts({})
      }
    },
    isElementAddedInReport() {
      return this.reportElements.some(
        re => re.type === 'fi_contacts' && re.property === 'include_contacts'
      )
    },
    contactElement() {
      return this.reportElements.find(el => el.type === 'fi_contacts')
    }
  },
  methods: {
    ...mapActions('AdvancedQuery', ['checkQueryValidityForRun']),
    ...mapMutations('AdvancedQuery', [
      'setFIContactCount',
      'setFIsWithContactCount'
    ]),
    ...mapMutations('ReportWriter', [
      'addReportElement',
      'setContactTitles',
      'setContactRoles',
      'setLoadingContactCount',
      'removeElementByProperty',
      'updateReportElementByProperty'
    ]),
    submitFilterSelection() {
      this.fetchTotalContacts({})
    },
    loadInitData() {
      this.loadTitlesAndRoles()
    },
    loadTitlesAndRoles() {
      if (this.contactTitles.length && this.contactRoles.length) {
        return Promise.resolve()
      }
      this.$wait.start('loadingContactDetails')
      return reportWriterElementsAPI
        .titlesAndRoles()
        .then(res => {
          this.setContactTitles(res.titles)
          this.setContactRoles(res.roles)
        })
        .finally(() => {
          this.$wait.end('loadingContactDetails')
        })
    },
    resetContactData() {
      this.removeElementByProperty({
        property: 'include_contacts',
        type: 'fi_contacts'
      })
      this.includeContacts = false
      this.setFIContactCount(null)
    },
    fetchTotalContacts(options) {
      this.checkQueryValidityForRun().then(
        () => {
          this.$wait.start('fetchingContactCount')
          let query = new QueryTransformer().transform

          this.setLoadingContactCount(true)

          return advancedQueryAPI
            .contactCount(query, this.isMortgageLender)
            .then(
              res => {
                this.setFIContactCount(res.contact_count)
                this.setFIsWithContactCount(res.count_fi_with_contacts)
                this.setLoadingContactCount(false)
              },
              () => {
                this.resetContactData()
                this.setLoadingContactCount(false)
              }
            )
            .finally(() => {
              this.$wait.end('fetchingContactCount')
            })
        },
        () => {
          if (!options.skipReset) {
            this.resetContactData()
          }

          this.$toasted.global.validation_error(
            'Invalid Query, please check query validity.'
          )
        }
      )
    },
    updateContactCount() {
      if (this.includeContacts) {
        this.fetchTotalContacts({ skipReset: true })
      }
    },
    formatTitle(title) {
      let formattedTitle = {
        id: title.id,
        label: title.value
      }

      if (title.child && title.child.length) {
        formattedTitle.children = title.child.map(ct => this.formatTitle(ct))
      }
      return formattedTitle
    },
    formatRole(role) {
      let formattedRole = {
        id: role.id,
        label: role.value
      }

      if (role.child && role.child.length) {
        formattedRole.children = role.child.map(cr => this.formatRole(cr))
      }

      return formattedRole
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-count {
  div {
    display: inline-block;
  }
}
.contact-options-card {
}
</style>

<style lang="scss">
.contact-options-card {
  .multiselect {
    .multiselect__tags {
      border-color: #c2cfd6;
    }

    .multiselect__select {
      &::before {
        border-color: #999 transparent transparent;
        border-width: 5px 5px 0;
      }
    }
  }
}
</style>
