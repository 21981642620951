import { render, staticRenderFns } from "./MLDataElement.vue?vue&type=template&id=31b2d470&scoped=true&"
import script from "./MLDataElement.vue?vue&type=script&lang=js&"
export * from "./MLDataElement.vue?vue&type=script&lang=js&"
import style0 from "./MLDataElement.vue?vue&type=style&index=0&id=31b2d470&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31b2d470",
  null
  
)

export default component.exports