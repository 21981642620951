var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row no-gutters metrics-segment"},[_c('div',{staticClass:"col-sm-3"},[_c('h6',{},[_vm._v("Metric Categories")]),_c('div',{staticClass:"card std-border mb-0 metric-menu tree-menu segment-column"},[_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"pt-2"},[_c('ul',{staticClass:"common-metric-list-group pl-0 font-weight-bold"},_vm._l((_vm.metricsMenu),function(product,index){return _c('li',{key:index,class:[
                  {
                    active: _vm.selectedMetric.product_id === product.product_id
                  },
                  'py-1 list-group-item pl-3'
                ],on:{"click":function($event){return _vm.selectProduct(product)}}},[_vm._v(" "+_vm._s(product.name)+" ")])}),0)])])])]),_c('div',{class:[_vm.showInfo ? 'col-sm-6' : 'col-sm-9', 'px-2 details-holder']},[(_vm.selectedMetric.name)?_c('h6',{},[_vm._v(" "+_vm._s(_vm.selectedMetric.name)+" ")]):_c('h6',{staticClass:"mb-4"}),_c('div',{staticClass:"card std-border mb-0 metric-details segment-column"},[_c('div',{staticClass:"card-body p-1"},[_c('v-wait',{attrs:{"for":"loadingCommonTreeData"}},[_c('template',{slot:"waiting"},[_c('content-placeholders',{staticClass:"loader",attrs:{"rounded":true}},[_c('content-placeholders-text',{attrs:{"lines":20}})],1)],1),_c('div',{staticClass:"row p-3"},[_c('div',{staticClass:"offset-sm-4 col-sm-2 text-right align-self-center"},[_c('h6',{staticClass:"mb-0"},[_vm._v("Search")])]),_c('div',{staticClass:"col-sm-6 pl-0"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Type text to search..."},domProps:{"value":(_vm.searchText)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchText=$event.target.value}}})])]),_c('LiquorTree',{ref:"commonMetricsTree",attrs:{"data":_vm.treeData,"multiple":false,"showChildren":true,"options":_vm.treeOptions,"filter":_vm.searchText},on:{"node:clicked":_vm.nodeClicked,"node:checked":_vm.nodeChecked,"node:unchecked":_vm.nodeUnChecked},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var node = ref.node;
return _c('span',{staticClass:"tree-text"},[[_c('span',{attrs:{"id":'cm-' + node.id.toString()}},[_vm._v(" "+_vm._s(node.text)+" ")]),_c('b-popover',{attrs:{"target":'cm-' + node.id.toString(),"triggers":"hover","placement":"right","boundary":"window"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(node.data.bank_sec_title)+" ")]),_vm._v(" "+_vm._s(_vm.getMatchingDescription( node.data.bank_metric_id, node.data.cu_metric_id ).bank_description)+" "),_c('div',{staticClass:"font-weight-bold mt-3"},[_vm._v(" "+_vm._s(node.data.cu_sec_title)+" ")]),_vm._v(" "+_vm._s(_vm.getMatchingDescription( node.data.bank_metric_id, node.data.cu_metric_id ).cu_description)+" ")])]],2)}}])})],2)],1)])]),(_vm.showInfo)?_c('div',{staticClass:"col-sm-3"},[_c('h6',{staticClass:"mb-4"}),_c('div',{staticClass:"card std-border mb-0 metric-info segment-column"},[(_vm.selectedNode)?_c('div',{staticClass:"card-body p-2 mt-1"},[_c('h6',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.selectedNode.data.tree_display))]),_c('span',{staticClass:"sub-title"},[_vm._v(_vm._s(_vm.selectedNode.data.sec_title))]),_c('p',{staticClass:"mt-3"},[_vm._v(_vm._s(_vm.selectedNode.data.description))])]):_vm._e()])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }