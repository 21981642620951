<template>
  <div>
    <LiquorTree
      :data="elements"
      :multiple="false"
      :showChildren="true"
      :parentSelect="false"
      :options="treeOptions"
      ref="segmentTree"
      @node:checked="nodeChecked"
      @node:unchecked="nodeUnChecked"
    >
      <span class="tree-text" slot-scope="{ node }">
        <template>
          <span :id="'sg-' + node.id.toString()">
            {{ node.text }}
          </span>
          <b-popover
            :target="'sg-' + node.id.toString()"
            triggers="hover"
            placement="right"
            boundary="window"
            v-if="node.data.description"
          >
            {{ node.data.description }}
          </b-popover>
        </template>
      </span>
    </LiquorTree>
  </div>
</template>

<script>
//global
import { mapState, mapMutations } from 'vuex'
//ui
import LiquorTree from 'liquor-tree'

export default {
  name: 'RWSegmentation',
  components: {
    LiquorTree
  },
  props: {
    selectedReport: {
      type: Object,
      required: true
    },
    showOutput: {
      type: Boolean,
      default: true
    },
    renderedIn: {
      type: String,
      default: 'Report Writer'
    }
  },
  data() {
    return {
      treeOptions: {
        checkbox: true,
        checkOnSelect: false,
        parentSelect: false,
        autoCheckChildren: false
      },
      elements: []
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      elementsInfo: state => state.elementsInfo,
      reportElements: state => state.reportElements,
      elementSequenceID: state => state.elementSequenceID,
      segmentationOptions: state => state.segmentationOptions
    }),
    ...mapState('AdvancedQuery', {
      queryElements: state => state.queryElements
    }),
    segmentElementsInfo() {
      return this.elementsInfo.filter(ei => ei.segment === 'segmentation')
    }
  },
  methods: {
    ...mapMutations('ReportWriter', [
      'addReportElement',
      'addCustomizedElement',
      'removeElementByProperty',
      'removeCustomizedElement',
      'updateSequenceID'
    ]),
    loadInitData() {
      this.prepareTreeData()
    },
    isElementAddedInQuery(option) {
      return this.queryElements.some(
        qe => qe.type === 'segmentation' && qe.property === option.property
      )
    },
    isElementAddedInReport(option) {
      return this.reportElements.some(
        re => re.type === 'segmentation' && re.property === option.property
      )
    },
    isElementAdded(option) {
      return (
        this.isElementAddedInReport(option) ||
        this.isElementAddedInQuery(option)
      )
    },
    prepareTreeData() {
      this.elements = []

      this.segmentationOptions.forEach((option, index) => {
        this.elements.push({
          id: index + 1,
          text: option.name,
          data: {
            property: option.property,
            description: this.segmentElementsInfo.find(
              ei => ei.property === option.property
            ).description
          },
          state: {
            checked: this.isElementAdded(option),
            disabled: this.isElementAddedInQuery(option)
          }
        })
      })

      this.$refs.segmentTree.setModel(this.elements)
    },
    nodeChecked(node) {
      this.updateSequenceID()
      let element = {
        name: node.text,
        type: 'segmentation',
        property: node.data.property,
        seqID: this.elementSequenceID
      }
      this.addReportElement(element)
      this.addCustomizedElement(element)
    },
    nodeUnChecked(node) {
      this.removeCustomizedElement({
        property: node.data.property,
        type: 'segmentation'
      })
      this.removeElementByProperty({
        property: node.data.property,
        type: 'segmentation'
      })
    }
  },
  watch: {}
}
</script>
