<template>
  <div>
    <v-wait for="loadingBankServices">
      <template slot="waiting">
        <content-placeholders :rounded="true" class="loader">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>

      <div class="row no-gutters technographics-segment pt-1">
        <div class="col-sm-3">
          <h6 class="">Technographics Categories</h6>
          <div
            class="card std-border mb-0 technographics-menu tree-menu segment-column"
          >
            <div class="card-body p-0">
              <div
                v-for="(serviceGroup, index) in treeData"
                :key="index"
                class="pb-3"
              >
                <div class="font-weight-bold text-uppercase px-2 pt-2">
                  {{ serviceGroup.text }}
                </div>
                <ul class="list-group">
                  <li
                    :class="[
                      {
                        active: selectedService.data === childService.data
                      },
                      'list-group-item pl-4'
                    ]"
                    v-for="(childService, index) in serviceGroup.children"
                    :key="index"
                    @click="selectService(childService, serviceGroup.text)"
                  >
                    {{ childService.text }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="[showInfo ? 'col-sm-6' : 'col-sm-9', 'px-2 details-holder']"
        >
          <h6 class="" v-if="selectedParent">
            {{ selectedParent }} : {{ selectedService.text }}
          </h6>
          <div class="card std-border mb-0 techno-details segment-column">
            <div class="card-body p-1">
              <div class="row p-3">
                <div class="offset-sm-4 col-sm-2 text-right align-self-center">
                  <h6 class="mb-0">Search</h6>
                </div>

                <div class="col-sm-6 pl-0">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Type text to search..."
                    v-model="searchText"
                  />
                </div>
              </div>

              <LiquorTree
                :data="treeData"
                :multiple="false"
                :showChildren="true"
                :parentSelect="false"
                :options="treeOptions"
                :filter="searchText"
                ref="solutionTree"
                @node:selected="nodeSelected"
                @node:checked="nodeChecked"
                @node:unchecked="nodeUnChecked"
              >
                <span class="tree-text" slot-scope="{ node }">
                  <template>
                    <span :id="'tg-' + node.id">
                      {{ node.text }}
                    </span>
                    <b-popover
                      :target="'tg-' + node.id"
                      triggers="hover"
                      placement="right"
                      boundary="window"
                      v-if="getDescription(node.data)"
                    >
                      {{ getDescription(node.data) }}
                    </b-popover>
                  </template>
                </span>
              </LiquorTree>
            </div>
          </div>
        </div>
        <div class="col-sm-3" v-if="showInfo">
          <h6 class="mb-4"></h6>
          <div class="card std-border mb-0 techno-info segment-column">
            <div class="card-body">
              <h6 class="mb-0">{{ selectedItem.text }}</h6>
              <p class="mt-3">{{ selectedItem.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </v-wait>
  </div>
</template>

<script>
//global
import { mapState, mapMutations } from 'vuex'
//api
import bankServicesAPI from '@/api/finapps/bank_services'
//ui
import LiquorTree from 'liquor-tree'

export default {
  name: 'Technographics',
  created() {},
  components: {
    LiquorTree
  },
  props: {
    selectedReport: {
      type: Object,
      required: true
    },
    showOutput: {
      type: Boolean,
      default: true
    },
    renderedIn: {
      type: String,
      default: 'Report Writer'
    },
    isMortgageLender: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      treeData: null,
      selectedParent: null,
      selectedService: {},
      selectedItem: {},
      searchText: null,
      treeOptions: {
        checkbox: true,
        checkOnSelect: false,
        parentSelect: false,
        autoCheckChildren: false,
        autoDisableChildren: false
      }
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      bankServices: state => state.bankServices,
      reportElements: state => state.reportElements,
      elementSequenceID: state => state.elementSequenceID
    }),
    ...mapState('AdvancedQuery', {
      queryElements: state => state.queryElements
      //isMortgageLender: state => state.isMortgageLender
    }),
    editMode() {
      return this.selectedReport.id !== null
    },
    showInfo() {
      return !this.showOutput
    },
    activeCustomElementIDs() {
      return this.reportElements
        .filter(re => re.type === 'technographics')
        .map(te => parseInt(te.id))
    },
    activeQueryElementIDs() {
      return this.queryElements
        .filter(qe => qe.type === 'technographics')
        .map(te => parseInt(te.elementID))
    },
    activeElementIDs() {
      return [...this.activeCustomElementIDs, ...this.activeQueryElementIDs]
    },
    forFinQuery() {
      return this.renderedIn === 'FIN Query'
    }
  },
  methods: {
    ...mapMutations('ReportWriter', [
      'setBankServices',
      'addReportElement',
      'addCustomizedElement',
      'removeReportElement',
      'removeCustomizedElement',
      'updateSequenceID'
    ]),
    loadInitData() {
      return this.getBankServices().then(() => {
        this.treeData = this.convertToHierarichalData(this.bankServices)
        this.setDefaultService()
      })
    },
    setDefaultService() {
      let defaultService = this.treeData[0]
      this.selectedParent = defaultService.text
      this.selectedService = defaultService.children[0]
      this.$refs.solutionTree.setModel(this.selectedService.children)
    },
    convertToHierarichalData(services = [], id = null, link = 'parent_id') {
      return services
        .filter(service =>
          id === null
            ? !services.some(s => s.id === service[link])
            : service[link] === id
        )
        .map(service => {
          return {
            id: service.id,
            text: service.name,
            data: service,
            state: {
              expanded: true,
              checked: this.activeElementIDs.includes(service.id),
              disabled: this.activeQueryElementIDs.includes(service.id)
                ? true
                : this.isMortgageLender
                ? this.isDisabled(service.name)
                : false
            },
            children: this.convertToHierarichalData(services, service.id)
          }
        })
    },
    isDisabled(name) {
      return [
        'ACH & Wire Payments',
        'Social Media',
        'Mortgage Loan Origination'
      ].includes(name)
        ? true
        : false
    },
    getBankServices() {
      if (this.bankServices.length) {
        return Promise.resolve()
      }

      this.$wait.start('loadingBankServices')

      return bankServicesAPI
        .treeData(this.forFinQuery ? this.isMortgageLender : false)
        .then(res => {
          this.setBankServices(res.bank_services)
        })
        .finally(() => {
          this.$wait.end('loadingBankServices')
        })
    },
    selectService(service, parentName) {
      let childServices = this.convertToHierarichalData(
        this.bankServices,
        service.id
      )
      this.searchText = null
      this.selectedParent = parentName
      this.selectedService = service
      this.$refs.solutionTree.setModel(
        childServices.length
          ? childServices
          : [
              {
                id: service.id,
                text: service.text,
                data: service,
                state: {
                  expanded: true,
                  checked: this.activeElementIDs.includes(service.id),
                  disabled: this.activeQueryElementIDs.includes(service.id)
                },
                children: []
              }
            ]
      )
    },
    nodeSelected(node) {
      this.selectedItem = node
    },
    addToReportElements(node) {
      let element = {
        id: node.data.id,
        seqID: this.elementSequenceID,
        name: node.text,
        type: 'technographics',
        property: ['default']
      }
      this.addReportElement(element)
      this.addCustomizedElement(element)
    },
    nodeChecked(node) {
      this.updateSequenceID()
      this.selectedItem = node
      this.addToReportElements(node)
    },
    nodeUnChecked(node) {
      this.selectedItem = node
      this.removeCustomizedElement({
        id: node.data.id,
        type: 'technographics'
      })
      this.removeReportElement({ id: node.data.id, type: 'technographics' })
    },
    getDescription(nodeData) {
      return nodeData.data ? nodeData.data.description : nodeData.description
    }
  }
}
</script>
<style lang="scss" scoped>
.technographics-segment {
  .details-holder.col-sm-6 {
    transition: 0.5s;
  }

  .details-holder.col-sm-9 {
    transition: 0.5s;
  }

  .segment-column.technographics-menu {
    height: 60rem;
  }

  .segment-column.techno-details {
    height: 60rem;
  }
}
</style>
