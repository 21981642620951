<template>
  <div>
    <v-wait for="loadingStatements">
      <template slot="waiting">
        <content-placeholders :rounded="true" class="loader">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>

      <div class="row no-gutters financial-statements-segment">
        <div class="col-sm-3">
          <h6 class="">Statement Categories</h6>
          <div
            class="card std-border mb-0 financial-statements-menu tree-menu segment-column"
          >
            <div class="card-body p-0">
              <div
                v-for="(statementGroup, index) in treeData"
                :key="index"
                class="pb-3"
              >
                <div
                  :class="[
                    {
                      active: selectedStatement.data === statementGroup.data,
                      'cursor-pointer': !hasSubMenu(statementGroup)
                    },
                    'font-weight-bold text-uppercase px-2 pt-2 menu-header'
                  ]"
                  @click="selectStatementGroup(statementGroup)"
                >
                  {{ statementGroup.text }}
                </div>
                <ul class="list-group" v-if="statementGroup.children.length">
                  <li
                    :class="[
                      {
                        active: selectedStatement.data === childStatement.data
                      },
                      'list-group-item pl-4'
                    ]"
                    v-for="(childStatement, index) in statementGroup.children"
                    :key="index"
                    @click="selectStatement(childStatement)"
                  >
                    {{ childStatement.text }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="[showInfo ? 'col-sm-6' : 'col-sm-9', 'px-2 details-holder']"
        >
          <h6 class="" v-if="selectedStatement.product_name">
            {{ selectedStatement.product_name }}:
            {{ selectedStatement.menu_display }}
          </h6>
          <h6 v-else class="mb-4"></h6>
          <div class="card std-border mb-0 statement-details segment-column">
            <div class="card-body p-1">
              <v-wait for="loadingTreeData">
                <template slot="waiting">
                  <content-placeholders :rounded="true" class="loader">
                    <content-placeholders-text
                      :lines="20"
                    ></content-placeholders-text>
                  </content-placeholders>
                </template>

                <div class="row p-3">
                  <div
                    class="offset-sm-4 col-sm-2 text-right align-self-center"
                  >
                    <h6 class="mb-0">Search</h6>
                  </div>

                  <div class="col-sm-6 pl-0">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Type text to search..."
                      v-model="searchText"
                    />
                  </div>
                </div>

                <LiquorTree
                  :data="selectedStatementData"
                  :multiple="false"
                  :showChildren="true"
                  :options="treeOptions"
                  :filter="searchText"
                  @node:clicked="nodeClicked"
                  @node:checked="nodeChecked"
                  @node:unchecked="nodeUnChecked"
                  ref="statementsTree"
                >
                  <span class="tree-text" slot-scope="{ node }">
                    <template>
                      <span :id="'fm-' + node.id.toString()">
                        {{ node.text }}
                      </span>
                      <b-popover
                        :target="'fm-' + node.id.toString()"
                        triggers="hover"
                        placement="right"
                        boundary="window"
                        v-if="node.data.sec_title"
                      >
                        <div class="font-weight-bold">
                          {{ node.data.sec_title }}
                        </div>
                        {{ node.data.description }}
                      </b-popover>
                    </template>
                  </span>
                </LiquorTree>
              </v-wait>
            </div>
          </div>
        </div>
        <div class="col-sm-3" v-if="showInfo">
          <h6 class="mb-4"></h6>
          <div class="card std-border mb-0 metric-info segment-column">
            <div class="card-body p-2 mt-1" v-if="selectedNode">
              <h6 class="mb-0">{{ selectedNode.data.tree_display }}</h6>
              <span class="sub-title">{{ selectedNode.data.sec_title }}</span>
              <p class="mt-3">{{ selectedNode.data.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </v-wait>
  </div>
</template>

<script>
// global
import { mapState, mapMutations } from 'vuex'
//utilities
import _ from 'lodash'
import { findDeep } from 'deepdash'
//api
import financialStatementsAPI from '@/api/finapps/financial_statements'
//need to refactor
import LiquorTree from 'liquor-tree'

export default {
  name: 'FinancialStatements',
  components: {
    LiquorTree
  },
  props: {
    selectedReport: {
      type: Object,
      required: true
    },
    showOutput: {
      type: Boolean,
      default: true
    },
    renderedIn: {
      type: String,
      default: 'Report Writer'
    }
  },
  data() {
    return {
      selectedStatement: {},
      selectedStatementData: {},
      searchText: null,
      selectedNode: null,
      treeData: [],
      treeOptions: {
        checkbox: true,
        checkOnSelect: false,
        parentSelect: false,
        autoCheckChildren: false,
        autoDisableChildren: false
      }
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      activeSegment: state => state.activeSegment,
      reportElements: state => state.reportElements,
      elementSequenceID: state => state.elementSequenceID,
      financialStatementsData: state => state.financialStatementsData,
      financialCommonStatementsData: state =>
        state.financialCommonStatementsData
    }),
    ...mapState('AdvancedQuery', {
      queryElements: state => state.queryElements
    }),
    showInfo() {
      return !this.showOutput
    },
    fiType() {
      return this.selectedReport.fi_type
    },
    statementsByFIType() {
      return _.groupBy(this.financialStatementsData, s => s.fi_type)
    },
    financialStatements() {
      if (this.fiType === 'both') {
        return this.financialCommonStatementsData
      } else {
        return this.statementsByFIType[this.fiType]
      }
    },
    elementType() {
      return this.fiType === 'both'
        ? 'common_financial_statements'
        : 'financial_statements'
    },
    activeCustomElementIDs() {
      return this.reportElements
        .filter(re => re.type === this.elementType)
        .map(te => parseInt(te.id))
    },
    activeQueryElementIDs() {
      return this.queryElements
        .filter(qe => qe.type === this.elementType)
        .map(te => parseInt(te.elementID))
    },
    activeElementIDs() {
      return [...this.activeCustomElementIDs, ...this.activeQueryElementIDs]
    },
    isActiveSegment() {
      return this.activeSegment === 'Financial Statements'
    }
  },
  methods: {
    ...mapMutations('ReportWriter', [
      'addReportElement',
      'addCustomizedElement',
      'removeReportElement',
      'removeCustomizedElement',
      'setFinancialStatementsData',
      'setFinancialCommonStatementsData',
      'updateSequenceID'
    ]),
    loadInitData() {
      return this.getFinancialStatementDetails().then(() => {
        this.createStatementsTree()
        this.$nextTick(() => {
          this.$refs.statementsTree.setModel(this.treeData)
          this.setDefaultStatement()
        })
      })
    },
    getFinancialStatementDetails() {
      if (
        this.financialStatementsData.length &&
        this.financialCommonStatementsData.length
      ) {
        return Promise.resolve()
      }

      this.$wait.start('loadingStatements')

      return financialStatementsAPI
        .all()
        .then(res => {
          this.setFinancialCommonStatementsData(res.common_statements)
          this.setFinancialStatementsData(res.statements)
        })
        .finally(() => {
          this.$wait.end('loadingStatements')
        })
    },
    createStatementsTree() {
      this.treeData = this.financialStatements.map(fs =>
        this.createStatementNode(fs)
      )
    },
    createStatementNode(statement) {
      if (statement.children && statement.children.length) {
        let node = this.getNodeData(statement)
        let sortedChildrens = statement.children.order
          ? statement.children.sort((a, b) => (a.order > b.order ? 1 : -1))
          : statement.children

        node.children = sortedChildrens.map(child =>
          this.createStatementNode(child)
        )
        return node
      } else if (statement.tree) {
        let node = this.getNodeData(statement)
        node.children = this.createStatementNode(statement.tree)
        return node
      } else {
        return this.getNodeData(statement)
      }
    },
    setDefaultStatement() {
      this.selectedStatement = this.treeData[0].children[0]
      this.selectStatement(this.selectedStatement)
    },
    getNodeData(statement) {
      return {
        id: statement.id,
        text: statement.name,
        data: {
          treeID: statement.tree_id,
          fiType: statement.fi_type,
          value: statement.value,
          parent_id: statement.parent_id,
          element_id: statement.element_id
        },
        state: {
          expanded: true,
          checked: this.activeElementIDs.includes(statement.element_id),
          disabled: this.activeQueryElementIDs.includes(statement.element_id)
        }
      }
    },
    addToReportElements(node) {
      let element = {
        id: node.data.element_id,
        seqID: this.elementSequenceID,
        name: node.text,
        type: this.elementType,
        scope: 'single',
        property: 'value',
        span: 2,
        set: 1
      }
      this.addReportElement(element)
      this.addCustomizedElement(element)
    },
    nodeClicked(node) {
      this.selectedNode = node
    },
    nodeChecked(node) {
      this.updateSequenceID()
      this.selectedNode = node
      this.addToReportElements(node)
    },
    nodeUnChecked(node) {
      this.removeCustomizedElement({
        id: node.data.element_id,
        type: this.elementType
      })
      this.removeReportElement({
        id: node.data.element_id,
        type: this.elementType
      })
    },
    selectStatement(statement) {
      this.searchText = null
      this.selectedStatement = statement

      let matchStatement = findDeep(
        this.financialStatements,
        function(fs) {
          return fs.id === statement.id
        },
        {
          childrenPath: 'children'
        }
      )

      let subTree = this.createStatementNode(matchStatement.value.tree)
      this.$refs.statementsTree.setModel(subTree)
    },
    selectStatementGroup(statement) {
      if (!this.hasSubMenu(statement)) {
        this.selectStatement(statement)
      }
    },
    hasSubMenu(statement) {
      return statement.children.length
    }
  },
  watch: {
    fiType: {
      immediate: true,
      handler: function() {
        if (this.isActiveSegment) {
          this.loadInitData()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.financial-statements-segment {
  .menu-header {
    &.active {
      background-color: #20a8d8;
      padding-bottom: 0.5rem;
    }
  }

  .details-holder.col-sm-6 {
    transition: 0.5s;
  }

  .details-holder.col-sm-9 {
    transition: 0.5s;
  }

  .segment-column.financial-statements-menu {
    height: 60rem;
  }

  .segment-column.statement-details {
    height: 60rem;
  }
}
</style>
