<template>
  <div>
    this is a tab
  </div>
</template>

<script>
export default {
  name: 'RWRegulatoryStatements',
  methods: {
    loadInitData() {}
  }
}
</script>
