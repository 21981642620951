<template>
  <div>
    <v-wait for="loadingMenu">
      <template slot="waiting">
        <content-placeholders :rounded="true" class="loader">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>

      <div class="row mb-2 metric-config pr-2">
        <div
          :class="[
            showInfo ? 'offset-sm-9 col-sm-2' : 'offset-sm-8 col-sm-3',
            'subchapter-option text-right align-self-center'
          ]"
        >
          <h6 class="mb-0">Subchapter SCorp</h6>
        </div>
        <div
          :class="[
            isBank ? 'cursor-pointer' : 'option-disabled',
            'col-sm-1 align-self-center'
          ]"
        >
          <c-switch
            type="text"
            variant="success"
            on="Yes"
            off="No"
            :pill="true"
            size="sm"
            :checked="isSCorp"
            v-model="isSCorp"
            @change="sCorpChanged()"
            :disabled="!isBank || editMode"
            class="mb-0"
          ></c-switch>
        </div>
      </div>

      <div class="row no-gutters metrics-segment">
        <div class="col-sm-3">
          <h6 class="">Metric Categories</h6>
          <div
            class="card std-border mb-0 metric-menu tree-menu segment-column"
          >
            <div class="card-body p-0">
              <div>
                <div
                  v-for="(menuItems, productName) in menuData"
                  :key="productName"
                  class="pb-3"
                >
                  <div class="font-weight-bold text-uppercase px-2 pt-2">
                    {{ productName }}
                  </div>
                  <ul class="list-group">
                    <li
                      :class="[
                        {
                          active:
                            selectedMetric.menu_display === menu.menu_display
                        },
                        'list-group-item pl-4'
                      ]"
                      v-for="(menu, index) in menuItems"
                      :key="index"
                      @click="setActive(menu)"
                    >
                      {{ menu.menu_display }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          :class="[showInfo ? 'col-sm-6' : 'col-sm-9', 'px-2 details-holder']"
        >
          <h6 class="" v-if="selectedMetric.product_name">
            {{ selectedMetric.product_name }}: {{ selectedMetric.menu_display }}
          </h6>
          <h6 v-else class="mb-4"></h6>
          <div class="card std-border mb-0 metric-details segment-column">
            <div class="card-body p-1">
              <v-wait for="loadingTreeData">
                <template slot="waiting">
                  <content-placeholders :rounded="true" class="loader">
                    <content-placeholders-text
                      :lines="20"
                    ></content-placeholders-text>
                  </content-placeholders>
                </template>

                <div class="row p-3">
                  <div
                    class="offset-sm-4 col-sm-2 text-right align-self-center"
                  >
                    <h6 class="mb-0">Search</h6>
                  </div>

                  <div class="col-sm-6 pl-0">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Type text to search..."
                      v-model="searchText"
                    />
                  </div>
                </div>

                <LiquorTree
                  :data="selectedMetricData"
                  :multiple="false"
                  :showChildren="true"
                  :options="treeOptions"
                  :filter="searchText"
                  @node:clicked="nodeClicked"
                  @node:checked="nodeChecked"
                  @node:unchecked="nodeUnChecked"
                  ref="metricTree"
                >
                  <span class="tree-text" slot-scope="{ node }">
                    <template>
                      <span :id="'fm-' + node.id.toString()">
                        {{ node.text }}
                      </span>
                      <b-popover
                        :target="'fm-' + node.id.toString()"
                        triggers="hover"
                        placement="right"
                        boundary="window"
                        v-if="node.data.sec_title"
                      >
                        <div class="font-weight-bold">
                          {{ node.data.sec_title }}
                        </div>
                        {{
                          getMatchingDescription(node.data.metric_id)
                            .description
                        }}
                      </b-popover>
                    </template>
                  </span>
                </LiquorTree>
              </v-wait>
            </div>
          </div>
        </div>
        <div class="col-sm-3" v-if="showInfo">
          <h6 class="mb-4"></h6>
          <div class="card std-border mb-0 metric-info segment-column">
            <div class="card-body p-2 mt-1" v-if="selectedNode">
              <h6 class="mb-0">{{ selectedNode.data.tree_display }}</h6>
              <span class="sub-title">{{ selectedNode.data.sec_title }}</span>
              <p class="mt-3">{{ selectedNode.data.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </v-wait>
  </div>
</template>

<script>
//utilities
import _ from 'lodash'
import { mapState, mapMutations } from 'vuex'
//api
import reportMenuAPI from '@/api/finapps/report_menu_items'
import metricTreeRelationshipsAPI from '@/api/finapps/metric_tree_relationships'
//need to refactor
import LiquorTree from 'liquor-tree'
//ui components
import cSwitch from '@/components/Switch'

export default {
  name: 'FinancialMetrics',
  components: {
    cSwitch,
    LiquorTree
  },
  props: {
    selectedReport: {
      type: Object,
      required: true
    },
    showOutput: {
      type: Boolean,
      default: true
    },
    renderedIn: {
      type: String,
      default: 'Report Writer'
    }
  },
  data() {
    return {
      isSCorp: false,
      selectedMetric: {},
      groupedMetricData: [],
      selectedMetricData: {},
      searchText: null,
      selectedNode: null,
      treeData: [],
      treeOptions: {
        checkbox: true,
        checkOnSelect: false,
        parentSelect: false,
        autoCheckChildren: false,
        autoDisableChildren: false
      },
      metricDescriptions: null
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      activeSegment: state => state.activeSegment,
      metricMenuData: state => state.metricMenuData,
      metricTreeData: state => state.metricTreeData,
      reportElements: state => state.reportElements,
      elementSequenceID: state => state.elementSequenceID,
      savedReportElements: state => state.savedReportElements
    }),
    ...mapState('AdvancedQuery', {
      queryElements: state => state.queryElements
    }),
    showInfo() {
      return !this.showOutput
    },
    fiType() {
      return this.selectedReport.fi_type
    },
    isBank() {
      return this.fiType === 'bank'
    },
    treeId() {
      switch (this.fiType) {
        case 'bank':
          return this.isSCorp ? 2 : 1
        case 'bhc':
          return 1
        case 'credit_union':
          return 3
        default:
          return 1
      }
    },
    groupedMenuData() {
      return _.groupBy(this.metricMenuData, m => `${m.fi_type}_${m.tree_id}`)
    },
    menuData() {
      let menu = this.groupedMenuData[`${this.fiType}_${this.treeId}`]
      return _.groupBy(menu, 'product_name')
    },
    editMode() {
      return this.selectedReport.id !== null
    },
    productMetrics() {
      if (!this.selectedMetric) {
        return []
      }
      return this.metricTreeData[this.treeId].filter(
        td => td.product_id === this.selectedMetric.product_id
      )
    },
    selectedOption() {
      return this.productMetrics.find(
        pm => pm.metric_id === this.selectedMetric.metric_id
      )
    },
    activeCustomElementIDs() {
      return this.reportElements
        .filter(re => re.type === 'metrics')
        .map(te => parseInt(te.id))
    },
    activeQueryElementIDs() {
      return this.queryElements
        .filter(qe => qe.type === 'metrics')
        .map(te => parseInt(te.elementID))
    },
    activeElementIDs() {
      return [...this.activeCustomElementIDs, ...this.activeQueryElementIDs]
    },
    isActiveSegment() {
      return this.activeSegment === 'Financial Metrics'
    },
    metricIDsToBeSkipped() {
      if (!this.selectedMetric) {
        return []
      }

      return this.menuData[this.selectedMetric.product_name]
        .filter(md => md.metric_id !== this.selectedMetric.metric_id)
        .map(md => md.metric_id)
    }
  },
  methods: {
    ...mapMutations('ReportWriter', [
      'setMetricMenuData',
      'setMetricTreeData',
      'addReportElement',
      'addCustomizedElement',
      'removeReportElement',
      'removeCustomizedElement',
      'removeMetricFromGraphEl',
      'updateSequenceID'
    ]),
    loadInitData() {
      Promise.all([
        this.getMetricMenu(),
        this.getMetricTreeData(),
        this.getMetricTreeDescriptions()
      ]).then(() => {
        this.setDefaultMetric()
        this.$nextTick(() => {
          this.prepareTree()
          this.$refs.metricTree.setModel(this.treeData)
        })
      })
      if (this.editMode) {
        this.isSCorp = this.selectedReport.subs
      }
    },
    setDefaultMetric() {
      let [firstProduct] = Object.keys(this.menuData)
      this.selectedMetric = this.menuData[firstProduct][0]
    },
    prepareTree() {
      this.treeData = this.createTreeNode(
        this.selectedOption,
        this.productMetrics
      )
    },
    createTreeNode(metric, metrics) {
      return {
        id: metric.id,
        text: metric.tree_display,
        data: metric,
        state: {
          expanded: true,
          checked: this.activeElementIDs.includes(metric.metric_id),
          disabled: this.activeQueryElementIDs.includes(metric.metric_id)
        },
        children: this.convertToTree(metrics, metric.key_id)
      }
    },
    convertToTree(metrics = [], id = null, link = 'key_parent_id') {
      return metrics
        .filter(
          metric =>
            metric[link] === id &&
            !this.metricIDsToBeSkipped.includes(metric.metric_id)
        )
        .map(metric => this.createTreeNode(metric, metrics))
    },
    getMetricMenu() {
      if (this.metricMenuData.length) {
        return Promise.resolve()
      }

      this.$wait.start('loadingMenu')

      return reportMenuAPI
        .all()
        .then(res => {
          this.setMetricMenuData(res.menu_items)
        })
        .finally(() => {
          this.$wait.end('loadingMenu')
        })
    },
    getMetricTreeData() {
      if (Object.keys(this.metricTreeData).length) {
        return Promise.resolve()
      }

      this.$wait.start('loadingTreeData')

      return metricTreeRelationshipsAPI
        .treeRelationships(this.treeId, this.selectedReport.id)
        .then(res => {
          this.setMetricTreeData(res.relationships)
        })
        .finally(() => {
          this.$wait.end('loadingTreeData')
        })
    },
    getMetricTreeDescriptions() {
      if (this.metricDescriptions) {
        return Promise.resolve()
      }

      return metricTreeRelationshipsAPI.metricDescriptions().then(res => {
        this.metricDescriptions = res.relationships
      })
    },
    getMatchingDescription(metricID) {
      if (!this.metricDescriptions) {
        return {}
      }

      return (
        this.metricDescriptions[this.treeId].find(
          m => m.metric_id === metricID
        ) || {}
      )
    },
    setActive(menu) {
      this.selectedMetric = menu
      this.searchText = null
      this.prepareTree()
      this.$refs.metricTree.setModel(this.treeData)
    },
    addToReportElements(node) {
      let element = {
        id: node.data.metric_id,
        seqID: this.elementSequenceID,
        name: node.text,
        type: 'metrics',
        scope: 'single',
        property: 'value',
        span: 2,
        set: 1
      }
      this.addReportElement(element)
      this.addCustomizedElement(element)
    },
    nodeClicked(node) {
      this.selectedNode = node
    },
    nodeChecked(node) {
      this.updateSequenceID()
      this.selectedNode = node
      this.addToReportElements(node)
    },
    nodeUnChecked(node) {
      this.removeCustomizedElement({
        id: node.data.metric_id,
        type: 'metrics'
      })
      this.removeMetricFromGraphEl({
        id: node.data.metric_id,
        type: 'metrics'
      })
      this.removeReportElement({ id: node.data.metric_id, type: 'metrics' })
    },
    hasUnsavedChanges() {
      if (this.editMode) {
        return !_.isEqual(this.reportElements, this.savedReportElements)
      } else {
        return this.reportElements.length
      }
    },
    sCorpChanged() {
      this.loadInitData()
    }
  },
  watch: {
    fiType: {
      immediate: true,
      handler: function() {
        if (this.isActiveSegment && this.fiType !== 'both') {
          this.loadInitData()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.metrics-segment {
  .details-holder.col-sm-6 {
    transition: 0.5s;
  }

  .details-holder.col-sm-9 {
    transition: 0.5s;
  }

  .metric-config {
    .offset-sm-4,
    .offset-sm-5 {
      transition: 0.5s;
    }

    .subhapter-option {
      &.col-sm-2 {
        transition: 0.5s;
      }
      &.col-sm-3 {
        transition: 0.5s;
      }
    }
  }
}
</style>
