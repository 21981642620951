<template>
  <div>
    <v-wait for="loadingTreeData">
      <template slot="waiting">
        <content-placeholders :rounded="true" class="loader">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div class="row w-100">
        <div class="col-12 filter-group-modifier">
          <div
            class="d-flex mb-2"
            v-for="(child, index) in mlElementList"
            :key="index"
          >
            <MLDataElement
              :queryRules="queryRules"
              :query="child"
              :rootQuery="mlElementList"
              :index="index"
              @updateRule="updateRule"
              @cloneRule="cloneRule"
              @removeRule="removeRule"
              @addFilter="addFilter"
            ></MLDataElement>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 filter-group-modifier">
          <button
            type="button"
            class="btn btn-primary rounded mr-1"
            @click="addDataElement()"
          >
            Add Data Element
          </button>
        </div>
      </div>
    </v-wait>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import MLDataElement from './MLDataElement'
import deepClone from '@/utilities.js'

export default {
  name: 'RWMortgageLending',
  components: {
    MLDataElement
  },
  props: {
    selectedReport: {
      type: Object,
      required: true
    },
    showOutput: {
      type: Boolean,
      default: true
    },
    renderedIn: {
      type: String,
      default: 'Report Writer'
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapState('AdvancedQuery', ['queryRules']),
    ...mapState('ReportWriter', [
      'reportElements',
      'customizedReportElements',
      'mlElementList'
    ]),
    chosenRule() {
      return this.queryRules.find(rule => rule.label === 'Mortgage Lending')
    },
    actionTakenOperandsForML() {
      return this.chosenRule && this.chosenRule.operators.action_taken
    },
    outputTypeOperandsForML() {
      return this.chosenRule && this.chosenRule.operators.output_type
    }
  },
  methods: {
    ...mapMutations('ReportWriter', [
      'addReportElement',
      'addCustomizedElement',
      'removeReportElement',
      'removeCustomizedElement',
      'updateSequenceID',
      'removeReportElementByName',
      'saveMlElementList'
    ]),
    addFilter(child) {
      let query = child
      query.filters.push({
        id: this.generateNewFilterId(query),
        ruleId: query.id,
        filterId: null,
        type: 'filter',
        operator: null,
        operand: null,
        childOperand: null,
        value: [],
        count: null,
        error: false,
        loading: false
      })
      let childIndex = this.mlElementList.findIndex(
        item => item.id === child.id
      )
      this.mlElementList[childIndex] = query
    },
    generateNewFilterId(query) {
      let len = query.filters.length
      if (len > 0) {
        let oldId = query.filters[len - 1].id.split('_').pop()
        let newId = parseInt(oldId) + 1
        return '1_' + query.id + '_' + newId
      } else {
        return '1_' + query.id + '_' + 1
      }
    },
    cloneRule(item) {
      let updateQuery = deepClone(this.mlElementList)
      let clonedRule = deepClone(item)

      clonedRule.id = this.mlElementList.length + 1
      this.updateFilterIds(clonedRule)
      updateQuery.push(clonedRule)

      this.saveMlElementList(updateQuery)
    },
    removeRule(item) {
      let updateQuery = deepClone(this.mlElementList)
      let ruleIndex = updateQuery.findIndex(child => child.id === item.id)
      updateQuery.splice(ruleIndex, 1)
      this.saveMlElementList(updateQuery)
      let name = this.getDisplayText(item)
      this.removeCustomizedElement({
        name: name,
        type: 'mortgage_lending'
      })
      this.removeReportElementByName({
        name: name,
        type: 'mortgage_lending'
      })
    },
    updateFilterIds(item) {
      item.filters.forEach(f => {
        f.ruleId = item.id
        let oldUniqId = f.id.split('_').pop()
        f.id = '1_' + item.id + '_' + oldUniqId
      })
    },
    addDataElement() {
      let filterId = this.generateNewId()
      let list = deepClone(this.mlElementList)
      list.push({
        id: filterId,
        operand: null,
        operandLabel: null,
        actionTaken: [],
        outputType: null,
        filterOperator: 'AND',
        filterOn: false,
        filters: [
          {
            id: '1_' + filterId + '_1',
            ruleId: '1_' + filterId,
            filterId: null,
            type: 'filter',
            childOperand: null,
            value: [],
            count: null,
            error: false,
            operand: null,
            operator: null,
            loading: false
          }
        ]
      })
      this.saveMlElementList(list)
    },
    generateNewId() {
      let len = this.mlElementList.length
      if (len > 0) {
        let oldId = this.mlElementList[len - 1].id
        let newId = parseInt(oldId) + 1
        return newId
      } else {
        return 1
      }
    },
    updateRule(item) {
      let list = deepClone(this.mlElementList)
      let childIndex = list.findIndex(li => li.id === item.id)
      list[childIndex] = item
      this.saveMlElementList(list)
      let existingElement = this.reportElements.find(re => re.ruleId == item.id)
      let element = {}
      if (existingElement) {
        //element = existingElement
        existingElement.name = this.getDisplayText(item)
        existingElement.property = item.operand
        existingElement.actionTaken = item.actionTaken
        existingElement.outputType = item.outputType
        existingElement.filterOn = item.filterOn
        existingElement.filterOperator = item.filterOperator
        existingElement.filters = item.filters
      } else {
        this.updateSequenceID()
        element = {
          name: this.getDisplayText(item),
          type: 'mortgage_lending',
          property: item.operand,
          seqID: this.elementSequenceID,
          ruleId: item.id,
          actionTaken: item.actionTaken,
          outputType: item.outputType,
          filterOn: item.filterOn,
          filterOperator: item.filterOperator,
          filters: item.filters
        }
        this.addReportElement(element)
        this.addCustomizedElement(element)
      }
    },
    getDisplayText(item) {
      let filterList = this.queryRules.find(
        rule => rule.label === 'Mortgage Lending'
      ).filters
      let filterText = ''
      if (item.filters[0] && item.filters[0].filterId) {
        let fList = item.filters.map(f => this.getFilterText(f, filterList))
        filterText = '[' + fList.join(' ' + item.filterOperator + ' ') + ']'
      }
      let text = ''
      let atList = item.actionTaken.map(at => at.label)
      text += '[' + atList.join(', ') + ']: '
      text += item.outputTypeLabel.replace(/Loan|Record/gi, '') + ': '
      text += filterText
      return text
    },
    getFilterText(filter, filterList) {
      let operators = {
        equal: '=',
        less: '<',
        'less or equal': '<=',
        greater: '>',
        'greater or equal': '>=',
        between: 'BETWEEN',
        'not between': 'NOT BETWEEN'
      }
      let text = ''
      let selectedFilter = filterList.find(f => f.id === filter.filterId)
      if (selectedFilter) {
        text = '{'
        //text += selectedFilter.label + ': '
        text += selectedFilter.children.find(
          c => c.id === filter.childOperand.id
        ).label
        text += ' ' + operators[filter.operator]
        text +=
          ' (' + filter.value.map(v => (v.label ? v.label : v)).join(', ') + ')'
        text += '}'
      }
      return text
    },
    loadInitData() {},
    updateMlElementList() {
      let loadedMLElements = []
      this.reportElements.forEach(re => {
        if (re.type === 'mortgage_lending') {
          loadedMLElements.push({
            id: re.ruleId,
            operand: null,
            operandLabel: null,
            actionTaken: re.actionTaken,
            outputType: re.outputType,
            outputTypeLabel: re.outputType.label,
            filterOperator: re.filterOperator,
            filterOn: re.filterOn, //re.filters.length > 0,

            filters: re.filters
          })
        }
      })
      this.saveMlElementList(
        loadedMLElements.length > 0
          ? loadedMLElements
          : [
              {
                id: 1,
                operand: null,
                operandLabel: null,
                actionTaken: [],
                outputType: null,
                filterOperator: 'AND',
                filterOn: false,

                filters: [
                  {
                    id: '1_1_1',
                    ruleId: '1_1',
                    filterId: null,
                    type: 'filter',
                    childOperand: null,
                    value: [],
                    count: null,
                    error: false,
                    operand: null,
                    operator: null,
                    loading: false
                  }
                ]
              }
            ]
      )
    }
  }
}
</script>
