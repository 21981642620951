<template>
  <div class="px-1 mb-1 row w-100 rule-box-cnt form-inline std-border rounded">
    <div class=" w-100">
      <div class="col-12 form-inline">
        <multiselect
          v-model="query.actionTaken[0]"
          track-by="value"
          label="label"
          :options="actionTakenOperandsForML"
          :multiple="false"
          :close-on-select="true"
          :showLabels="false"
          placeholder="Select one"
          class="simple-select group-select form-control rounded advanced-query-select value-multi-select"
          @close="setDependents"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option-text">{{ props.option.label }}</span>
          </template>
          <template
            slot="selection"
            slot-scope="{ values }"
            v-if="values.length"
          >
            {{ values.length }}
            {{ values.length | pluralize('option') }} selected
          </template>
          <template slot="option" slot-scope="props">
            <div class="d-flex">
              <div class="option-text" :id="`at-${props.option.value}`">
                {{ props.option.label }}
              </div>
            </div>
            <b-popover
              :target="`at-${props.option.value}`"
              triggers="hover"
              placement="right"
              boundary="window"
              v-if="props.option.description"
            >
              {{ props.option.description }}
            </b-popover>
          </template>
        </multiselect>
        <multiselect
          v-model="query.outputType"
          track-by="value"
          label="label"
          :options="outputTypeOperandsForML"
          :multiple="false"
          :close-on-select="true"
          :showLabels="false"
          placeholder="Select one"
          class="simple-select group-select form-control rounded advanced-query-select value-multi-select ml-2"
          @close="setOperandsForML"
        >
          <template slot="singleLabel" slot-scope="props">
            <span class="option-text">{{ props.option.label }}</span>
          </template>
        </multiselect>
        <!-- <select
          class="form-control ml-2"
          v-model="query.outputType"
          @change="setOperandsForML"
        >
          <option value="null" selected disabled>
            Please Select
          </option>
          <option
            v-for="(at, index) in outputTypeOperandsForML"
            :key="index"
            :value="at.value"
            >{{ at.label }}</option
          >
        </select> -->
        <div class="close-icon">
          <i
            :class="['enabled', 'fa fa-clone pr-2 clone-rule-icon']"
            @click="cloneRule"
          ></i>
          <i class="fa fa-times-circle fa-lg" @click="removeRule"></i>
        </div>
      </div>
    </div>

    <div class="px-1 mb-1 row w-100">
      <div
        class="px-1 mb-1 col-11 ml-4 mt-2 filter-depth form-inline std-border rounded filter-section"
      >
        <div class="pt-1 pb-2 px-2 mt-1 mb-1">
          <span class="pr-2"> <b>Mortgage Loan/Application Filter</b></span>
          <c-switch
            type="text"
            variant="success"
            on="Yes"
            off="No"
            :pill="true"
            size="md"
            :checked="query.filterOn"
            @change="toggleShowFilters($event)"
          />
        </div>
        <div
          class="w-100"
          v-if="
            query.filterOn &&
              chosenRule &&
              chosenRule.label == 'Mortgage Lending'
          "
        >
          <FilterGroup
            :query="query"
            :rootQuery="emptyObject"
            :filters="chosenRule.filters"
            renderedIn="Report"
            @updateQuery="updateQuery"
            @filterUpdated="filterUpdated"
            @clearResult="clearResult"
            @addFilter="addFilter"
          ></FilterGroup>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
//import deepClone from '@/utilities.js'
import cSwitch from '@/components/Switch'
import FilterGroup from '@/modules/advanced_query/components/FilterGroup'

export default {
  name: 'MLDataElement',
  components: {
    Multiselect,
    cSwitch,
    FilterGroup
  },
  props: {
    queryRules: {
      type: Array
    },
    query: {
      type: Object
    },
    rootQuery: {
      type: Array
    },

    index: {
      type: Number
    }
  },
  data() {
    return {
      emptyObject: {}
    }
  },
  computed: {
    chosenRule() {
      return this.queryRules.find(rule => rule.label === 'Mortgage Lending')
    },
    actionTakenOperandsForML() {
      return this.chosenRule && this.chosenRule.operators.action_taken
    },
    outputTypeOperandsForML() {
      return this.chosenRule && this.chosenRule.operators.output_type
    },
    filterOptions() {
      let opt = this.chosenRule.filters.map(f => {
        return { value: f.id, text: f.label }
      })
      opt.push({ value: null, text: 'Please Select' })
      return opt
    },
    chosenFilter() {
      if (!this.query.filterId) {
        return null
      }

      return this.chosenRule.filters.find(f => f.id === this.query.filterId)
    },
    filterChildOperands() {
      if (!this.hasChildOperand) {
        return []
      }
      return this.chosenFilter.children.map(operand => {
        return { id: operand.id, label: operand.label }
      })
    },
    hasChildOperand() {
      return this.chosenFilter && this.chosenFilter.children.length > 0
    },
    emptyValue() {
      let value = this.query.actionTaken
      return Array.isArray(value)
        ? this.query.actionTaken.length === 0
        : [undefined, ''].includes(this.query.actionTaken)
    }
  },
  methods: {
    addFilter() {
      this.$emit('addFilter', this.query)
    },
    cloneRule() {
      this.$emit('cloneRule', this.query)
    },
    removeRule() {
      this.$emit('removeRule', this.query)
    },
    updateQuery(updateQuery) {
      // this.query = updateQuery
      this.$emit('updateRule', updateQuery)
    },
    ruleIdChanged() {
      this.query.childOperand = null
    },
    // saveDataElement() {
    //   let updateQuery = deepClone(this.query)
    //   this.$emit('filterUpdated', updateQuery)
    // },
    setDependents() {
      this.query.operator = null
      this.query.value = []

      this.$emit('updateRule', this.query)
    },
    filterUpdated(item) {
      //let updateQuery = deepClone(item)
      this.$emit('updateRule', item)
    },
    toggleShowFilters(event) {
      this.query.filterOn = event
      this.query.filters = [
        {
          id: this.query.id + '_1',
          ruleId: this.query.id,
          filterId: null,
          type: 'filter',
          childOperand: null,
          value: [],
          count: null,
          error: false,
          operand: null,
          operator: null,
          loading: false
        }
      ]
    },
    setOperandsForML() {
      if (this.query.outputType) {
        this.query.outputTypeLabel = this.query.outputType.label
        this.query.operand = this.query.outputType.value
      }
      this.$emit('updateRule', this.query)
    },
    clearResult() {}
  }
}
</script>
<style lang="scss" scoped>
.rule-box-cnt {
  background-color: #f5f5f5;
  margin-left: 0.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.close-icon {
  margin-left: auto;
}
.filter-section {
  background-color: #e1f3fc;
}
.filter-depth {
  border-left: 3px solid #333333;
}
.option-text {
  font-size: 0.875rem;
}
</style>
