<template>
  <div>
    <LiquorTree
      :data="elements"
      :multiple="false"
      :showChildren="true"
      :parentSelect="false"
      :options="treeOptions"
      ref="performographicTree"
      @node:checked="nodeChecked"
      @node:unchecked="nodeUnChecked"
    >
      <span class="tree-text" slot-scope="{ node }">
        <template>
          <span :id="'pg-' + node.id.toString()">
            {{ node.text }}
          </span>
          <b-popover
            :target="'pg-' + node.id.toString()"
            triggers="hover"
            placement="right"
            boundary="window"
            v-if="node.data.description"
          >
            {{ node.data.description }}
          </b-popover>
        </template>
      </span>
    </LiquorTree>
  </div>
</template>

<script>
//global
import { mapState, mapMutations } from 'vuex'
//ui
import LiquorTree from 'liquor-tree'

export default {
  name: 'RWPerformographics',
  components: {
    LiquorTree
  },
  props: {
    selectedReport: {
      type: Object,
      required: true
    },
    showOutput: {
      type: Boolean,
      default: true
    },
    renderedIn: {
      type: String,
      default: 'Report Writer'
    }
  },
  data() {
    return {
      treeOptions: {
        checkbox: true,
        checkOnSelect: false,
        parentSelect: false,
        autoCheckChildren: false
      },
      elements: []
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      elementsInfo: state => state.elementsInfo,
      reportElements: state => state.reportElements,
      elementSequenceID: state => state.elementSequenceID,
      performographicOptions: state => state.performographicOptions
    }),
    ...mapState('AdvancedQuery', {
      queryElements: state => state.queryElements
    }),
    segmentElementsInfo() {
      return this.elementsInfo.filter(ei => ei.segment === 'performographics')
    },
    fiType() {
      return this.selectedReport.fi_type
    }
  },
  methods: {
    ...mapMutations('ReportWriter', [
      'addReportElement',
      'addCustomizedElement',
      'removeReportElement',
      'removeCustomizedElement',
      'updateSequenceID'
    ]),
    loadInitData() {
      this.prepareTreeData()
    },
    isElementAddedInQuery(option) {
      return this.queryElements.some(
        qe => qe.type === 'performographics' && qe.elementID === option.id
      )
    },
    isElementAddedInReport(option) {
      return this.reportElements.some(
        re => re.type === 'performographics' && re.id === option.id
      )
    },
    isElementAdded(option) {
      return (
        this.isElementAddedInReport(option) ||
        this.isElementAddedInQuery(option)
      )
    },
    prepareTreeData() {
      this.elements = []

      this.performographicOptions.forEach(option => {
        this.elements.push({
          id: option.id,
          text: option.name,
          data: {
            property: option.property,
            description: this.segmentElementsInfo.find(
              ei => ei.element_id === option.id
            ).description
          },
          state: {
            checked: this.isElementAdded(option),
            disabled: this.isElementAddedInQuery(option)
          }
        })
      })

      this.$refs.performographicTree.setModel(this.elements)
    },
    nodeChecked(node) {
      this.updateSequenceID()
      let element = {
        id: node.id,
        name: node.text,
        type: 'performographics',
        property: node.data.property,
        seqID: this.elementSequenceID
      }
      this.addReportElement(element)
      this.addCustomizedElement(element)
    },
    nodeUnChecked(node) {
      this.removeCustomizedElement({
        id: node.id,
        type: 'performographics'
      })
      this.removeReportElement({ id: node.id, type: 'performographics' })
    }
  },
  watch: {
    fiType() {
      this.prepareTreeData()
    }
  }
}
</script>
