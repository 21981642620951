var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-output-view"},[_c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-tabs',{attrs:{"card":""}},[_c('b-tab',{attrs:{"title":"Output","active":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"mb-0 d-inline-block"},[_c('b',[_vm._v("Output")])])]},proxy:true}])},[_c('b-card-text',[(_vm.hasNoElements)?_c('div',[_c('h6',{staticClass:"text-center text-muted p-4"},[_vm._v(" No Report Elements added. ")])]):_c('div',[_c('div',[(_vm.outputEditable)?_c('ul',{staticClass:"output-groups"},[(_vm.showDefaultElements)?_c('li',[_c('div',{staticClass:"mt-1",on:{"click":function($event){_vm.defaultElementsExpanded = !_vm.defaultElementsExpanded}}},[_c('span',{staticClass:"pr-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.defaultElementsExpanded ? '-' : '+')+" ")]),_c('h6',{staticClass:"d-inline-block font-weight-bold mb-1"},[_vm._v(" Default ")])]),(_vm.defaultElementsExpanded)?_c('ul',{staticClass:"group-items"},[_vm._l((_vm.defaultOptionsToDisplay),function(element,index){return _c('li',{key:index},[_c('span',{staticClass:"font-weight-bold pr-1"},[_vm._v("-")]),_vm._v(" "+_vm._s(element.name)+" ")])}),(_vm.actualQueryElements.length)?_c('li',{staticClass:"font-weight-bold pt-1"},[_vm._v(" Query Elements ")]):_vm._e(),_vm._l((_vm.actualQueryElements),function(element,index){return _c('li',{key:("qe_" + index),class:[
                          element === _vm.selectedQueryElement ? 'selected' : '',
                          'pb-1 pl-3'
                        ]},[(element.type === 'technographics')?_c('div',{on:{"click":function($event){return _vm.toggleQueryElement(element)}}},[_c('span',{staticClass:"font-weight-bold pr-1"},[_vm._v(_vm._s(_vm.selectedQueryElement === element ? '-' : '+'))]),_vm._v(" "+_vm._s(element.name)+" "),(_vm.selectedQueryElement === element)?_c('ul',{staticClass:"group-items"},[_c('li',{staticClass:"pt-1"},[_c('span',{staticClass:"font-weight-bold pr-1"},[_vm._v("-")]),_vm._v(" Default ")])]):_vm._e()]):(
                            [
                              'metrics',
                              'common_metric',
                              'financial_statements'
                            ].includes(element.type)
                          )?_c('div',{on:{"click":function($event){return _vm.toggleQueryElement(element)}}},[_c('span',{staticClass:"font-weight-bold pr-1"},[_vm._v(_vm._s(_vm.selectedQueryElement === element ? '-' : '+'))]),_vm._v(" "+_vm._s(element.name.replace('+', '').trim())+" "),(_vm.selectedQueryElement === element)?_c('ul',{staticClass:"group-items"},[_c('li',{staticClass:"pt-1"},[_c('span',{staticClass:"font-weight-bold pr-1"},[_vm._v("-")]),_vm._v(" Metric Value ")]),_c('li',{staticClass:"pt-1"},[_c('span',{staticClass:"font-weight-bold pr-1"},[_vm._v("-")]),_vm._v(" As Reported ")]),_c('li',{staticClass:"pt-1"},[_c('span',{staticClass:"font-weight-bold pr-1"},[_vm._v("-")]),_vm._v(" Trailing-12 Months ")]),_c('li',{staticClass:"pt-1"},[_c('span',{staticClass:"font-weight-bold pr-1"},[_vm._v("-")]),_vm._v(" Current Period ")])]):_vm._e()]):_c('div',[_c('span',{staticClass:"font-weight-bold pr-1"},[_vm._v("-")]),_vm._v(" "+_vm._s(element.name)+" ")])])})],2):_vm._e()]):_vm._e(),(_vm.customReportElements.length)?_c('li',[_c('div',{staticClass:"mt-2",on:{"click":function($event){_vm.customElementsExpanded = !_vm.customElementsExpanded}}},[_c('span',{staticClass:"pr-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.customElementsExpanded ? '-' : '+')+" ")]),_c('h6',{staticClass:"d-inline-block font-weight-bold mb-1"},[_vm._v(" Custom ")])]),(_vm.customElementsExpanded)?_c('ul',{staticClass:"group-items"},_vm._l((_vm.customReportElements),function(element,index){return _c('li',{key:index,class:[
                          element === _vm.selectedCustomElement ? 'selected' : '',
                          'pb-1'
                        ]},[(_vm.elementSelectable(element))?_c('div',[_c('div',{on:{"click":function($event){return _vm.toggleCustomElement(element)}}},[_c('span',{staticClass:"font-weight-bold pr-1"},[_vm._v(" "+_vm._s(_vm.selectedCustomElement === element ? '-' : '+')+" ")]),_vm._v(" "+_vm._s(element.type === 'vendor_fit_static' ? 'VendorFit : ' : '')+" "+_vm._s(element.name)+" ")]),_c('keep-alive',[(_vm.selectedCustomElement === element)?_c(((_vm.elementType(element)) + "-options"),{tag:"component",attrs:{"element":element,"editable":_vm.isEditable(element),"fiType":_vm.duplicateExists(element)
                                  ? _vm.getFiType(element)
                                  : ''},on:{"updateElement":_vm.updateCustomElement}}):_vm._e()],1)],1):_c('div',[_c('span',{staticClass:"font-weight-bold pr-1"},[_vm._v(" - ")]),_vm._v(" "+_vm._s(element.name)+" ")])])}),0):_vm._e()]):_vm._e()]):_c('ul',{staticClass:"output-groups"},[(_vm.irReportElements.length)?_c('li',[_c('div',{staticClass:"mt-2"},[_c('span',{staticClass:"pr-1 font-weight-bold"},[_vm._v(" - ")]),_c('h6',{staticClass:"d-inline-block font-weight-bold mb-1"},[_vm._v(" Custom ")])]),_c('ul',{staticClass:"group-items"},_vm._l((_vm.irReportElements),function(element,index){return _c('li',{key:index,class:[
                          element === _vm.selectedCustomElement ? 'selected' : '',
                          'pb-1'
                        ]},[_c('div',[_c('span',{staticClass:"font-weight-bold pr-1"},[_vm._v("-")]),_vm._v(" "+_vm._s(element.name)+" ")])])}),0)]):_vm._e()])])])])],1),(_vm.isCustomizable)?_c('b-tab',{attrs:{"title":"Format"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"mb-0 d-inline-block"},[_c('b',[_vm._v("Format")])])]},proxy:true}],null,false,3331862994)},[_c('b-card-text',[_c('output-view-formatter')],1)],1):_vm._e(),(_vm.outputEditable && _vm.customReportElements.length)?_c('b-tab',{attrs:{"title":"Global Settings"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"mb-0 d-inline-block"},[_c('b',[_vm._v("Global Settings")])])]},proxy:true}],null,false,1873829407)},[_c('b-card-text',[_c('keep-alive',[_c('global-settings',{attrs:{"renderedIn":_vm.renderedIn}})],1)],1)],1):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }