<template>
  <div>
    <ul class="">
      <li class="pt-1" @click.stop="toggleEditListItem()">
        <div class="row" v-if="!editable">
          <div :class="[editable ? 'col-sm-10' : 'col-sm-12']">
            <span class="font-weight-bold pr-1">-</span>
            {{ elementPropertyText }}
          </div>
          <div class="col-sm-2" v-if="editable">
            <i class="fa fa-caret-down"></i>
          </div>
        </div>
        <div v-else>
          <span class="font-weight-bold pr-1">-</span>
          <span class="multiselect-holder d-inline-block">
            <Multiselect
              track-by="id"
              label="text"
              :allowEmpty="false"
              :showLabels="false"
              :options="propertyOptions"
              :value="elementProperty"
              class="rw-multi-select"
              @input="updateProperty"
              :multiple="true"
            >
              <template
                slot="selection"
                v-if="elementProperty.length"
                slot-scope="{ values }"
              >
                {{ values.length }}
                {{ values.length | pluralize('option') }} selected
              </template>
            </Multiselect>
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  name: 'TechnographicsOptions',
  props: {
    element: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    fiType: {
      type: String,
      default: ''
    }
  },
  components: {
    Multiselect
  },
  data() {
    return {
      propertyOptions: [
        { id: 'default', text: 'Default' },
        { id: 'has_service', text: 'Has Service' },
        { id: 'vendor', text: 'Vendor' },
        { id: 'product', text: 'Product' }
      ]
    }
  },
  computed: {
    ...mapGetters('ReportWriter', ['globalSettings', 'globalTechnographics']),
    elementProperty() {
      if (
        this.element.type === 'technographics' &&
        this.globalSettings['Technographics'] === 'true'
      ) {
        return this.globalTechnographics['property']
      } else
        return this.propertyOptions.filter(property =>
          this.element.property.includes(property.id)
        )
    },
    elementPropertyText() {
      return this.elementProperty.map(ep => ep.text).join(', ')
    }
  },
  methods: {
    updateProperty(properties) {
      this.$emit('updateElement', {
        elementId: this.element.id,
        id: properties.map(p => p.id),
        type: 'technographics',
        property: 'property'
      })
    },
    toggleEditListItem() {}
  }
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding-inline-start: 15px;
}

.multiselect-holder {
  width: 92%;
}
</style>
