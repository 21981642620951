<template>
  <div>
    <ul class="">
      <li class="pt-1" @click.stop="toggleEditListItem()">
        <div class="row" v-if="!editable">
          <div :class="[editable ? 'col-sm-10' : 'col-sm-12']">
            <span class="font-weight-bold pr-1">-</span>
            Top {{ elementProperty.text }} Rank(s)
          </div>
          <div class="col-sm-2" v-if="editable">
            <i class="fa fa-caret-down"></i>
          </div>
        </div>
        <div v-else>
          <span class="font-weight-bold pr-1">-</span>
          <span class="d-inline-block">
            <div class="d-flex align-items-center">
              <span>Top </span>
              <span class="multiselect-holder d-inline-block px-2">
                <Multiselect
                  track-by="id"
                  label="text"
                  :allowEmpty="false"
                  :showLabels="false"
                  :options="leadingOptions"
                  :value="elementProperty"
                  class="rw-multi-select vendor-fit-select"
                  @input="updateProperty"
                  :multiple="false"
                  placeholder="Select"
                >
                </Multiselect>
              </span>
              <span> Rank(s)</span>
            </div>
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'vendorFitOptions',
  props: {
    element: {
      type: Object,
      required: true
    },
    editable: {
      type: Boolean,
      default: false
    },
    fiType: {
      type: String,
      default: ''
    }
  },
  components: {
    Multiselect
  },
  data() {
    return {
      leadingOptions: [
        { id: 1, text: 1 },
        { id: 2, text: 2 },
        { id: 3, text: 3 },
        { id: 4, text: 4 },
        { id: 5, text: 5 },
        { id: 6, text: 6 },
        { id: 7, text: 7 },
        { id: 8, text: 8 },
        { id: 9, text: 9 },
        { id: 10, text: 10 }
      ]
    }
  },
  computed: {
    elementProperty() {
      return this.leadingOptions.find(
        leading => leading.id === this.element.leading
      )
    }
  },
  methods: {
    updateProperty(property) {
      this.$emit('updateElement', {
        elementId: this.element.id,
        id: property.id,
        type: 'vendor_fit_static',
        property: 'leading'
      })
    },
    toggleEditListItem() {}
  }
}
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding-inline-start: 15px;
}

.multiselect-holder {
  width: 92%;
}
</style>

<style lang="scss">
.rw-multi-select {
  &.vendor-fit-select {
    .multiselect__tags {
      .multiselect__input {
        max-width: 50px;
      }
    }
  }
}
</style>
