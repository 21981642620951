var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{class:[
      _vm.showOutput ? 'col-sm-9 col-sm-9-mw-70' : 'col-sm-12',
      'rw-accordions px-1'
    ]},[_c('b-card',{attrs:{"body-class":"p-0"}},[_c('div',{attrs:{"role":"tablist"}},_vm._l((_vm.visibleSegments),function(segment,index){return _c('b-card',{key:index,class:[
            { 'active-card': _vm.activeSegment === segment },
            'mb-1 std-border'
          ],attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('b-btn',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("accordion_" + index)),expression:"`accordion_${index}`"}],staticClass:"accord-btn",attrs:{"block":"","href":"#"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"10"}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{class:['pr-2', _vm.icons[segment]]}),_c('h6',{staticClass:"mb-0"},[_c('b',[_vm._v(_vm._s(segment))]),(_vm.showPremiumContentMessage(segment))?_c('span',{staticClass:"text-muted small px-1"},[_vm._v("- Premium ($) Content ")]):_vm._e(),(_vm.showFINReporterNote(segment))?_c('span',{staticClass:"text-muted small px-1"},[_vm._v(" (Not available for Peer Group Average) ")]):(_vm.showIndustryReporterNote(segment))?_c('span',{staticClass:"text-muted small px-1"},[_vm._v(" (Not available for Industry Reporter) ")]):_vm._e()])])]),_c('b-col',{staticClass:"text-right",attrs:{"cols":"2"}},[_c('i',{staticClass:"fa fa-lg fa-caret-down",attrs:{"aria-hidden":"true"}})])],1)],1)],1),_c('b-collapse',{attrs:{"role":"tabpanel","id":("accordion_" + index),"visible":_vm.activeSegment === segment},on:{"shown":function($event){return _vm.selectSegment(segment)}}},[_c('b-card-body',{staticClass:"p-2"},[_c('keep-alive',[_c(_vm.segmentComponent(segment),{ref:segment.replace(/\W/g, ''),refInFor:true,tag:"component",class:{
                    'segment-disabled': _vm.showIndustryReporterNote(segment)
                  },attrs:{"selectedReport":_vm.selectedReport,"showOutput":_vm.showOutput,"renderedIn":_vm.renderedIn,"isMortgageLender":_vm.isMortgageLender}})],1)],1)],1)],1)}),1)])],1),(_vm.showOutput)?_c('div',{staticClass:"col-sm-4 col-sm-4-mw-30 px-1"},[_c('output-view-card',{attrs:{"output-editable":!_vm.renderedOnIndustryReporter,"renderedIn":_vm.renderedIn}})],1):_vm._e(),_c('b-modal',{attrs:{"id":"accessPremiumContent","centered":"","title":"Premium Content","ok-only":""},on:{"ok":function($event){_vm.show = false}}},[_c('p',{staticClass:"text-center"},[_vm._v(" Additional fees will apply for data elements labeled as "),_c('br'),_c('b',[_vm._v("Premium ($) Content")]),_vm._v(". ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }