<template>
  <div>
    <v-wait for="loadingTreeData">
      <template slot="waiting">
        <content-placeholders :rounded="true" class="loader">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      <LiquorTree
        :data="elements"
        :multiple="false"
        :showChildren="true"
        :parentSelect="false"
        :options="treeOptions"
        ref="digitalChannelTree"
        @node:checked="nodeChecked"
        @node:unchecked="nodeUnChecked"
      >
        <span class="tree-text" slot-scope="{ node }">
          <template>
            <span :id="'dc-' + node.id.toString()">
              {{ node.text }}
            </span>
            <b-popover
              :target="'dc-' + node.id.toString()"
              triggers="hover"
              placement="right"
              boundary="window"
              v-if="node.data.description"
            >
              {{ node.data.description }}
            </b-popover>
          </template>
        </span>
      </LiquorTree>
    </v-wait>
  </div>
</template>

<script>
//global
import { mapState, mapMutations } from 'vuex'
//ui
import LiquorTree from 'liquor-tree'
//api
import bankServicesAPI from '@/api/finapps/bank_services'

export default {
  name: 'RWDigitalChannels',
  components: {
    LiquorTree
  },
  props: {
    selectedReport: {
      type: Object,
      required: true
    },
    showOutput: {
      type: Boolean,
      default: true
    },
    renderedIn: {
      type: String,
      default: 'Report Writer'
    }
  },
  data() {
    return {
      treeOptions: {
        checkbox: true,
        checkOnSelect: false,
        parentSelect: false,
        autoCheckChildren: false
      },
      elements: []
    }
  },
  computed: {
    ...mapState('ReportWriter', {
      elementsInfo: state => state.elementsInfo,
      reportElements: state => state.reportElements,
      elementSequenceID: state => state.elementSequenceID,
      digitalChannelOptions: state => state.digitalChannelOptions,
      digitalChannelBankServices: state => state.digitalChannelBankServices
    }),
    ...mapState('AdvancedQuery', {
      queryElements: state => state.queryElements
    }),
    digitalElementsInfo() {
      return this.elementsInfo.filter(ei => ei.segment === 'digital_channels')
    },
    activeCustomElementIDs() {
      return this.reportElements
        .filter(re => re.type === 'technographics')
        .map(te => parseInt(te.id))
    },
    activeQueryElementIDs() {
      return this.queryElements
        .filter(qe => qe.type === 'technographics')
        .map(te => parseInt(te.elementID))
    },
    activeElementIDs() {
      return [...this.activeCustomElementIDs, ...this.activeQueryElementIDs]
    }
  },
  methods: {
    ...mapMutations('ReportWriter', [
      'addReportElement',
      'addCustomizedElement',
      'removeReportElement',
      'removeElementByProperty',
      'removeCustomizedElement',
      'setDigitalChannelBankServices',
      'updateSequenceID'
    ]),
    loadInitData() {
      this.getBankServicesData().then(() => {
        this.prepareTreeData()
      })
    },
    isElementAddedInQuery(option) {
      return this.queryElements.some(
        qe => qe.type === 'digital_channels' && qe.property === option.property
      )
    },
    isElementAddedInReport(option) {
      return this.reportElements.some(
        re => re.type === 'digital_channels' && re.property === option.property
      )
    },
    isElementAdded(option) {
      return (
        this.isElementAddedInReport(option) ||
        this.isElementAddedInQuery(option)
      )
    },
    getBankServicesData() {
      if (this.digitalChannelBankServices.length) {
        return Promise.resolve()
      }

      this.$wait.start('loadingTreeData')

      return bankServicesAPI
        .digitalChannelServices()
        .then(res => {
          this.setDigitalChannelBankServices(res.bank_services)
        })
        .finally(() => {
          this.$wait.end('loadingTreeData')
        })
    },
    prepareTreeData() {
      this.elements = []

      this.digitalChannelOptions.forEach((option, index) => {
        this.elements.push({
          id: index + 1,
          text: option.name,
          data: this.getNodeData(option),
          state: this.getNodeState(option)
        })
      })

      this.$refs.digitalChannelTree.setModel(this.elements)
    },
    isTechnographicNode(node) {
      return node.data.type === 'technographics'
    },
    getNodeData(option) {
      if (option.type === 'technographics') {
        let bankService = this.digitalChannelBankServices.find(
          ds => ds.name === option.bank_service_name
        )

        return {
          type: 'technographics',
          bank_service_id: bankService.id,
          description: bankService.description
        }
      } else {
        return {
          property: option.property,
          description: this.digitalElementsInfo.find(
            ei => ei.property === option.property
          ).description
        }
      }
    },
    getNodeState(option) {
      if (option.type === 'technographics') {
        let bankService = this.digitalChannelBankServices.find(
          ds => ds.name === option.bank_service_name
        )
        return {
          checked: this.activeElementIDs.includes(bankService.id),
          disabled: this.activeQueryElementIDs.includes(bankService.id)
        }
      } else {
        return {
          checked: this.isElementAdded(option),
          disabled: this.isElementAddedInQuery(option)
        }
      }
    },
    nodeChecked(node) {
      this.updateSequenceID()
      let element = this.isTechnographicNode(node)
        ? this.prepareTechnographicElement(node)
        : this.prepareDigitalChannelElement(node)

      this.addReportElement(element)
      this.addCustomizedElement(element)
    },
    prepareTechnographicElement(node) {
      return {
        id: node.data.bank_service_id,
        seqID: this.elementSequenceID,
        name: node.text,
        type: 'technographics',
        property: ['default']
      }
    },
    prepareDigitalChannelElement(node) {
      return {
        name: node.text,
        type: 'digital_channels',
        property: node.data.property,
        seqID: this.elementSequenceID
      }
    },
    nodeUnChecked(node) {
      if (this.isTechnographicNode(node)) {
        this.removeCustomizedElement({
          id: node.data.bank_service_id,
          type: 'metrics'
        })
        this.removeReportElement({
          id: node.data.bank_service_id,
          type: 'metrics'
        })
      } else {
        this.removeCustomizedElement({
          property: node.data.property,
          type: 'digital_channels'
        })
        this.removeElementByProperty({
          property: node.data.property,
          type: 'digital_channels'
        })
      }
    }
  },
  watch: {}
}
</script>
